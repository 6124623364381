<template>
    <div
      class="absolute inset-0 bg-cover bg-center"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <!-- Overlay para mejorar la legibilidad -->
      <div class="absolute inset-0 bg-indigo-900 opacity-50"></div>
      
      <!-- Atribución de Unsplash -->
      <div class="absolute bottom-2 right-2 text-white text-xs opacity-75 z-20">
        Photo by <a :href="photographerUrl" target="_blank" rel="noopener noreferrer" class="underline">{{ photographerName }}</a> on <a href="https://unsplash.com?utm_source=Aignis&utm_medium=referral" target="_blank" rel="noopener noreferrer" class="underline">Unsplash</a>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  
  const backgroundImage = ref('');
  const photographerName = ref('');
  const photographerUrl = ref('');
  
  const UNSPLASH_ACCESS_KEY = '9tOCuNE6gZagEXrDgSM97puqXTjkklnLGY9HMO_Gg3M';

  async function fetchDailyPhoto() {
    try {
      const cachedImage = localStorage.getItem('cachedBackgroundImage');
      const cachedTimestamp = localStorage.getItem('cachedBackgroundTimestamp');
      
      if (cachedImage && cachedTimestamp) {
        const currentTime = new Date().getTime();
        const cacheAge = currentTime - parseInt(cachedTimestamp);
        
        // Si la imagen en caché tiene menos de 24 horas, úsala
        if (cacheAge < 24 * 60 * 60 * 1000) {
          const cachedData = JSON.parse(cachedImage);
          backgroundImage.value = cachedData.imageUrl;
          photographerName.value = cachedData.photographerName;
          photographerUrl.value = cachedData.photographerUrl+'?utm_source=Aignis&utm_medium=referral';
          return;
        }
      }
      
      const response = await fetch(
        `https://api.unsplash.com/photos/random?orientation=landscape&query=nature&client_id=${UNSPLASH_ACCESS_KEY}`
      );
      const data = await response.json();
      
      backgroundImage.value = data.urls.regular;
      photographerName.value = data.user.name;
      photographerUrl.value = data.user.links.html;
      
      // Guardar en caché
      const cacheData = JSON.stringify({
        imageUrl: backgroundImage.value,
        photographerName: photographerName.value,
        photographerUrl: photographerUrl.value+'?utm_source=Aignis&utm_medium=referral'
      });
      localStorage.setItem('cachedBackgroundImage', cacheData);
      localStorage.setItem('cachedBackgroundTimestamp', new Date().getTime().toString());
    } catch (error) {
        console.error('Error fetching daily photo:', error);
        backgroundImage.value = '/background.jpg';
        photographerName.value = 'Vincent Guth';
        photographerUrl.value = 'https://unsplash.com/@vingtcent?utm_source=Aignis&utm_medium=referral';
    }
  }
  
  onMounted(() => {
    fetchDailyPhoto();
  });
  </script>
  
  