<template>
    <div class="w-full max-w-6xl mx-auto bg-gradient-to-b from-white to-gray-50 p-8 lg:p-12 shadow-xl rounded-2xl">
        <!-- Header -->
        <div class="flex flex-col items-center text-center mb-12">
            <img src="@/assets/img/logo-inverted.svg"
                alt="Aignis Logo" class="w-20 h-20 mb-4" />
            <h1
                class="text-4xl py-1 font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#4F46E5] to-[#7C3AED] mb-4">
                Aignis
            </h1>
            <p class="text-2xl font-medium text-gray-700 max-w-3xl">
                Potencia la Inteligencia de tu Negocio
            </p>
        </div>

        <!-- Main Feature -->
        <div
            class="mb-12 overflow-hidden border-0 bg-gradient-to-br from-[#4F46E5]/10 to-transparent rounded-lg shadow-md">
            <div class="p-8">
                <div class="flex flex-col md:flex-row items-center gap-8">
                    <div class="flex-1">
                        <h2 class="text-3xl font-bold mb-4 text-gray-800">IA Personalizada para tu Empresa</h2>
                        <p class="text-lg text-gray-600 mb-6">
                            Imagina poder conversar con una inteligencia artificial que comprende tu negocio como nadie
                            más, porque está equipada con tu propio conocimiento. Con Aignis, puedes transformar la
                            información de tu empresa en una experiencia conversacional única y efectiva, ya sea para
                            tus empleados o para tus clientes.
                        </p>
                        <a href="https://www.aignis.net" target="_blank" rel="noopener noreferrer"
                            class="group bg-[#4F46E5] text-white px-6 py-3 rounded-lg text-lg font-medium hover:bg-[#4F46E5]/90 transition-colors duration-300">
                            Comienza ahora
                            <ArrowRight
                                class="inline-block ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
                        </a>
                    </div>
                    <div class="flex-1 relative">
                        <div
                            class="absolute inset-0 bg-gradient-to-r from-[#4F46E5] to-[#7C3AED] rounded-full opacity-20 blur-3xl">
                        </div>
                        <Brain class="w-full h-auto text-[#4F46E5]" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Integration Features -->
        <h2 class="text-2xl font-bold mb-6 text-center">Cómo Aignis se Integra Contigo</h2>
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
            <div v-for="(feature, index) in integrationFeatures" :key="index"
                class="group hover:shadow-lg transition-all duration-300 border border-gray-100 rounded-lg bg-white">
                <div class="p-6">
                    <h3 class="flex items-center text-xl gap-3 font-semibold mb-3">
                        <component :is="feature.icon"
                            class="w-8 h-8 text-[#4F46E5] group-hover:scale-110 transition-transform duration-300" />
                        {{ feature.title }}
                    </h3>
                    <p class="text-gray-600">{{ feature.description }}</p>
                </div>
            </div>
        </div>

        <!-- Main Features -->
        <div class="mb-12 border-0 bg-gradient-to-br from-[#4F46E5]/5 to-transparent rounded-lg shadow-md">
            <div class="p-8">
                <h2 class="text-2xl font-bold flex items-center gap-3 mb-6">
                    <Sparkles class="w-8 h-8 text-[#4F46E5]" />
                    Principales Características
                </h2>
                <div class="grid md:grid-cols-2 gap-6">
                    <div v-for="(feature, index) in mainFeatures" :key="index" class="flex items-start gap-3 group">
                        <div
                            class="mt-1 w-6 h-6 rounded-full bg-[#4F46E5] text-white flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                            <component :is="feature.icon" class="w-4 h-4" />
                        </div>
                        <div>
                            <h3 class="font-semibold text-lg mb-1">{{ feature.title }}</h3>
                            <p class="text-gray-700">{{ feature.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Aignis in Action -->
        <div class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-center">Aignis en Acción</h2>
            <div class="grid md:grid-cols-2 gap-6">
                <div class="bg-white p-6 rounded-lg shadow-md border border-gray-100">
                    <Users class="w-12 h-12 text-[#4F46E5] mb-4" />
                    <p class="text-gray-700">Imagina a tu equipo consultando información detallada al instante, sin
                        tener que buscar entre documentos.</p>
                </div>
                <div class="bg-white p-6 rounded-lg shadow-md border border-gray-100">
                    <UserCheck class="w-12 h-12 text-[#4F46E5] mb-4" />
                    <p class="text-gray-700">Permite que tus clientes reciban respuestas precisas y al momento,
                        mejorando la experiencia y satisfacción.</p>
                </div>
            </div>
        </div>

        <!-- Call to Action -->
        <div class="bg-gradient-to-r from-[#4F46E5] to-[#7C3AED] text-white overflow-hidden rounded-lg shadow-lg">
            <div class="flex flex-col items-center text-center p-8 gap-6">
                <Zap class="w-16 h-16 mb-4" />
                <h2 class="text-3xl font-bold">Empieza Hoy con Aignis</h2>
                <p class="text-xl text-white/90 max-w-2xl">
                    Desata el verdadero potencial de tu base de conocimientos con Aignis. Contacta con nosotros para una
                    demostración o prueba gratuita y descubre cómo transformar la información en conocimiento
                    conversacional inteligente.
                </p>
                <a href="https://www.aignis.net" target="_blank" rel="noopener noreferrer"
                    class="group bg-white text-[#4F46E5] px-6 py-3 rounded-lg text-lg font-medium hover:bg-white/90 transition-all duration-300 mt-4">
                    Comienza ahora
                    <ArrowRight
                        class="inline-block ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            </div>
        </div>

        <!-- Footer -->
        <div class="mt-12 text-center text-gray-600">
            <p class="font-medium">Incorpora inteligencia a tu base de conocimiento con Aignis.</p>
            <p class="mt-2">Contacto: www.aignis.net</p>
        </div>
    </div>
</template>
  
  <script setup>
  import { ref } from 'vue'
  import { ArrowRight, Brain, Zap, Users, UserCheck, Database, Unplug, Server, Clock, RefreshCcw, Plug, Shield, Sparkles } from 'lucide-vue-next'
  
  const integrationFeatures = ref([
    { icon: Brain, title: "Conocimiento a Medida", description: "Alimenta a Aignis con tus documentos y archivos clave para respuestas precisas y alineadas con tu negocio." },
    { icon: Clock, title: "Acceso Rápido e Inteligente", description: "Obtén respuestas instantáneas a preguntas complejas, optimizando procesos internos y atención a clientes." },
    { icon: Unplug, title: "Integración via API", description: "Lleva la inteligencia de Aignis a cualquier herramienta que ya uses gracias a su potente API." },
    { icon: Database, title: "Conexión a Bases de Datos", description: "Realiza consultas SQL en tiempo real para proporcionar respuestas basadas en datos actualizados." },
    { icon: Server, title: "Instalación Local", description: "Instala Aignis en tus propios servidores para garantizar el máximo control y privacidad de tu información." }
  ])
  
  const mainFeatures = ref([
    { icon: Zap, title: "Respuesta Instantánea", description: "Aignis analiza y responde de manera ágil sin interrumpir el flujo de trabajo." },
    { icon: RefreshCcw, title: "Aprendizaje Continuo", description: "Se actualiza constantemente con la nueva información que le proporciones." },
    { icon: Plug, title: "Integración Flexible", description: "Se acopla perfectamente a cualquier software que ya uses mediante su API." },
    { icon: Shield, title: "Seguridad de Datos", description: "Tus datos siempre seguros y encriptados, asegurando confidencialidad y privacidad." }
  ])
  </script>

<!-- <style>
html {
    font-size: 280%; /* Valor base: 100% = 16px por defecto */
}
body {
    font-family: Arial, sans-serif;
    font-size: 1rem; /* Equivale a 16px */
}
h1 {
    font-size: 2rem; /* 2 veces el tamaño de `html`, o sea 32px */
}
p {
    font-size: 1rem; /* Equivalente al tamaño base de `html` */
}

.larger-fonts {
    font-size: 120%; /* Aumenta la fuente base de todo el documento en un 20% */
}
</style> -->