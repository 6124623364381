<template>
  <div
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50">
    <div class="w-full max-w-md bg-white rounded-lg shadow-xl overflow-hidden flex flex-col">
      <!-- Cabecera del popup -->
      <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
        <div class="flex items-center space-x-2">
          <img src="@/assets/img/logo.svg" alt="Aignis" class="h-6 w-auto" />
          <h3 class="text-xl font-bold">{{ $t('userProfile.title') }}</h3>
        </div>
        <button @click="emit('close')"
          class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
          <XIcon class="h-6 w-6" />
        </button>
      </div>

      <!-- Contenido del popup -->
      <div class="p-6 bg-gray-50">
        <form @submit.prevent="updateProfile" class="space-y-4">
          <!-- Campo de username -->
          <div class="relative">
            <label for="username" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
              {{ $t('userProfile.name') }}
            </label>
            <input type="text" id="username" v-model="user.username"
              class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              :placeholder="$t('userProfile.namePlaceholder')" required />
          </div>

          <!-- Campo de email -->
          <div class="relative">
            <label for="email" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
              {{ $t('userProfile.email') }}
            </label>
            <input type="email" id="email" v-model="user.email"
              class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              :placeholder="$t('userProfile.emailPlaceholder')" required />
          </div>

          <!-- Campo de contraseña -->
          <div class="relative">
            <label for="password" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
              {{ $t('userProfile.password') }}
            </label>
            <input type="password" id="password" v-model="user.password"
              class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              :placeholder="$t('userProfile.passwordPlaceholder')" />
          </div>

          <!-- Botones de acción -->
          <div class="flex items-center justify-center pt-4">
            <button type="submit"
              class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out">
              {{ $t('userProfile.save') }}
            </button>
          </div>

          <!-- Último intento de acceso fallido -->
          <div v-if="user.lastFailedLoginAttempt" class="text-xs text-gray-500 text-center mt-2">
            {{ $t('userProfile.lastFailedLogin') }} {{ new Date(user.lastFailedLoginAttempt).toLocaleString('es-ES', { day:
            '2-digit', month:
            '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
            }) }}
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, computed } from 'vue';
import { XIcon } from 'lucide-vue-next';
import { getUser, updateUser } from '../services/api';
import { useHead } from '@vueuse/head'
import { useNotification } from '@/plugins/notificationPlugin';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const pageTitle = computed(() => t('userProfile.title'));

useHead({
  title: pageTitle.value,
});

const { showSuccess, showError } = useNotification();
  
const emit = defineEmits(['close']);

const user = ref({
  username: '',
  email: '',
  password: '',
  lastFailedLoginAttempt: null
});

const fetchUserData = async () => {
  try {
    const response = await getUser();
    const userData = response.data;
    user.value.username = userData.username;
    user.value.email = userData.email;
    user.value.lastFailedLoginAttempt = userData.lastFailedLoginAttempt;
  } catch (error) {
    showError(error.detail || $t('userProfile.getDataError'));
  }
};

const updateProfile = async () => {
  try {
    const response = await updateUser(user.value);
    showSuccess(response.detail || 'Acción realizada con éxito.');
    emit('close');
  } catch (error) {
    showError(error.detail || 'Ha ocurrido un error');
  }
};

onMounted(fetchUserData);
</script>