<template>
  <div
    class="min-h-[100dvh] bg-gradient-to-br from-blue-50 to-indigo-100 flex justify-center items-center py-0 2xl:py-4">
    <div class="w-full max-w-7xl bg-white shadow-xl rounded-lg flex overflow-hidden h-[100dvh] 2xl:h-[95dvh]">

      <!-- Sidebar for conversation list -->
      <div
        :class="['bg-gray-100 w-80 flex-shrink-0 flex flex-col transition-all duration-300 ease-in-out', { '-ml-80': !showSidebar }]">
        <div class="p-4 pt-5 bg-indigo-700 text-white flex justify-between items-center">
          <span class="flex items-center justify-start">
            <MessagesSquare class="w-5 h-5 mr-2" />
            <h2 class="text-lg font-semibold">{{ $t('chat.conversations') }}</h2>
          </span>
          <button @click="toggleSidebar" class="text-white hover:text-indigo-200">
            <ChevronLeft v-if="showSidebar" class="h-6 w-6" />
          </button>
        </div>
        <div class="p-4 border-b border-gray-200 text-center">
          <a @click.prevent="startNewConversation" href="#"
            class="block w-full py-2 text-indigo-600 hover:text-indigo-500 font-semibold transition duration-300 ease-in-out text-left cursor-pointer">
            <span class="flex items-center justify-start">
              <SquarePlus class="w-4 h-4 mr-2 mt-1" />
              {{ $t('chat.newConversation') }}
            </span>
          </a>
        </div>
        <div class="flex-grow overflow-y-auto">
          <ul class="divide-y divide-gray-200">
            <li v-for="conversation in sortedConversations" :key="conversation.id" class="p-4 relative group">
              <div class="flex items-center justify-between">
                <div v-if="editingConversationId === conversation.id" class="flex-grow mr-2">
                  <input v-model="editingConversationName" @blur="saveConversationName(conversation)"
                    @keyup.enter="saveConversationName(conversation)" class="w-full px-2 py-1 border rounded"
                    :ref="el => { if (el) editNameInput = el }" />
                </div>
                <span v-else @click="loadConversation(conversation.id)"
                  class="text-left text-sm font-medium text-gray-700 hover:text-black cursor-pointer flex-grow mr-2 truncate">
                  {{ conversation.title }}
                </span>
                <div class="flex items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  <button @click="startEditingConversation(conversation)"
                    class="text-gray-400 hover:text-indigo-600 mr-2">
                    <Edit2Icon class="h-4 w-4" />
                  </button>
                  <button @click="confirmDeleteConversation(conversation.id)" class="text-gray-400 hover:text-red-600">
                    <TrashIcon class="h-4 w-4" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <!-- Disclaimer message and conditions -->
        <div class="px-4 py-6 text-center text-xs text-gray-500 border-t bg-gray-50 border-gray-200">
          <em>{{ $t('chat.disclaimer') }}
            <a href="#" @click.prevent="loadConditions" class="underline">{{ $t('chat.terms') }}</a>
          </em>
        </div>

      </div>

      <!-- Modal de confirmación para eliminar conversación -->
      <div v-if="showDeleteConfirm"
        class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4 z-40">
        <div class="bg-white rounded-lg p-6 shadow-xl">
          <p class="text-lg text-gray-800 mb-4">{{ $t('chat.confirmDeleteConversation') }}</p>
          <div class="flex justify-end space-x-4">
            <button @click="showDeleteConfirm = false" class="px-4 py-2 bg-gray-300 text-gray-800 rounded-md">{{
              $t('chat.cancel') }}</button>
            <button @click="deleteConversationConfirmed" class="px-4 py-2 bg-red-600 text-white rounded-md">{{
              $t('chat.delete') }}</button>
          </div>
        </div>
      </div>

      <!-- Main chat area -->
      <div class="flex-grow flex flex-col">
        <!-- Cabecera fija -->
        <div class="header bg-indigo-600 p-4 text-white flex justify-between items-center z-30">
          <div class="flex items-center space-x-2">
            <button @click="toggleSidebar" class="text-white hover:text-indigo-200">
              <ChevronRight v-if="!showSidebar" class="h-6 w-6" />
            </button>
            <img src="@/assets/img/logo.svg" alt="Aignis" class="h-8 w-auto" />
            <h1 class="text-xl font-bold">{{ $t('chat.title') }}</h1>
          </div>
          <div class="relative flex items-center space-x-4">
            <button v-if="isAdmin" @click="showKnowledgeBase = true; isMenuOpen = false"
              class="text-indigo-200 hover:text-white transition duration-150 ease-in-out">
              <Archive class="h-6 w-6" />
              <span class="sr-only">{{ $t('chat.menu.knowledge') }}</span>
            </button>
            <button @click="toggleMenu" class="text-indigo-200 hover:text-white transition duration-150 ease-in-out">
              <Menu class="h-6 w-6" />
              <span class="sr-only">{{ $t('chat.menuTitle') }}</span>
            </button>
            <div v-if="isMenuOpen" ref="menuRef"
              class="absolute top-6 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <button v-if="isAdmin" @click="showKnowledgeBase = true; isMenuOpen = false"
                  class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                  role="menuitem">
                  <Archive class="h-5 w-5 mr-3 text-indigo-600" />
                  {{ $t('chat.menu.knowledge') }}
                </button>
                <button @click="showUserProfile = true; isMenuOpen = false"
                  class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                  role="menuitem">
                  <User class="h-5 w-5 mr-3 text-indigo-600" />
                  {{ $t('chat.menu.yourUser') }}
                </button>
                <button v-if="isAdmin" @click="showUserManagement = true; isMenuOpen = false"
                  class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                  role="menuitem">
                  <Users class="h-5 w-5 mr-3 text-indigo-600" />
                  {{ $t('chat.menu.userManagement') }}
                </button>
                <button v-if="isAdmin" @click="showAccountManagement = true; isMenuOpen = false"
                  class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                  role="menuitem">
                  <Building2 class="h-5 w-5 mr-3 text-indigo-600" />
                  {{ $t('chat.menu.accountManagement') }}
                </button>
                <button v-if="isAdmin" @click="showAccountUsage = true; isMenuOpen = false"
                  class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                  role="menuitem">
                  <Gauge class="h-5 w-5 mr-3 text-indigo-600" />
                  {{ $t('chat.menu.accountUsage') }}
                </button>
                <button v-if="isSysAdmin" @click="showPricingPlans = true; isMenuOpen = false"
                  class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                  role="menuitem">
                  <Coins class="h-5 w-5 mr-3 text-indigo-600" />
                  {{ $t('chat.menu.pricingPlans') }}
                </button>
                <button v-if="isSysAdmin" @click="showAIProviderConfiguration = true; isMenuOpen = false"
                  class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                  role="menuitem">
                  <Bot class="h-5 w-5 mr-3 text-indigo-600" />
                  {{ $t('chat.menu.AIproviders') }}
                </button>
                <button @click="handleLogout"
                  class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                  role="menuitem">
                  <LogOut class="h-5 w-5 mr-3 text-indigo-600" />
                  {{ $t('chat.menu.logout') }}
                </button>
                <!-- Selector de idioma -->
                <div class="border-t border-gray-100"></div>
                <div class="px-4 py-2 text-sm text-gray-700 flex items-center justify-between">
                  <span class="font-medium">{{ $t('chat.menu.language') }}</span>
                  <div class="flex space-x-2">
                    <button @click="changeLanguage('en'); isMenuOpen = false" :class="['px-2 py-1 rounded-md text-xs font-medium', 
                  locale === 'en' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300']">
                      EN
                    </button>
                    <button @click="changeLanguage('es'); isMenuOpen = false" :class="['px-2 py-1 rounded-md text-xs font-medium', 
                  locale === 'es' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300']">
                      ES
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Contenedor principal del chat -->
        <div class="main-content flex-grow overflow-auto p-4 bg-gray-50" ref="chatContainer">

          <!-- Bocadillos de mensajes -->
          <div v-for="(message, index) in messages" :key="index" class="force-break-word mb-4 last:mb-0">
            <div :class="['flex', message.isUser ? 'justify-end' : 'justify-start']">
              <img v-if="!message.isUser" src="@/assets/img/logo-inverted.svg" alt="Aignis Logo"
                class="h-6 w-6 mr-4 mt-4 opacity-85 hidden md:block" />
              <div
                :class="['p-3 rounded-lg shadow', message.isUser ? 'bg-indigo-100 text-right' : 'w-full md:max-w-[85%] bg-white text-left']">
                <div v-if="message.isUser" class="userPrompt text-gray-800">
                  {{ message.text }}
                </div>
                <div v-else class="relative">
                  <div class="prose max-w-none text-gray-800" v-html="renderMarkdown(message.text)"></div>

                  <!-- Botón para copiar al portapapeles -->
                  <button @click="copyToClipboard(message.text, message.id)"
                    class="absolute -top-5 -right-1 p-2 text-gray-400 hover:text-indigo-600 transition duration-150 ease-in-out"
                    :title="$t('chat.copyToClipboard')">
                    <CheckIcon v-if="copiedMessageId === message.id" class="h-4 w-4" />
                    <CopyIcon v-else class="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Bocadillo esperando respuesta -->
          <div v-if="isThinking" class="mb-4 flex justify-start">
            <div class="max-w-[75%] p-3 rounded-lg shadow bg-gray-100 text-left text-gray-500 text-sm">
              <em>{{ thinkingMessage }}</em>
            </div>
          </div>
        </div>

        <!-- Campo para ingresar mensaje -->
        <div class="input-container p-3 bg-white border-t border-gray-200">
          <form @submit.prevent="sendMessage" class="flex flex-col space-y-2">
            <div class="flex items-center space-x-2">

              <div class="relative flex-grow">
                <textarea v-model="userInput" @input="adjustTextareaHeight" @keydown.enter="handleEnterKey"
                  class="w-full p-3 pr-10 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 resize-none overflow-y-auto max-h-[250px] custom-scroll-hidden"
                  :placeholder="$t('chat.writeYourMessage')" rows="1"></textarea>

                <!-- Botón para usar conexión a base de datos -->
                <button v-if="dbConnections && dbConnections.length > 0" @click="toggleDatabaseOptions" type="button"
                  class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-indigo-600 focus:outline-none">
                  <Database v-if="!requiresDatabaseQuery" class="h-5 w-5" />
                  <Database v-else class="h-5 w-5 text-indigo-600" />
                </button>

              </div>

              <!-- Botón de enviar mensaje -->
              <button type="submit"
                class="bg-indigo-600 hover:bg-indigo-700 text-white rounded-xl px-4 py-3 h-[46px] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out flex items-center justify-center"
                :disabled="isLoading">
                <Send v-if="!isLoading" class="h-5 w-5" />
                <Loader v-else class="h-5 w-5 animate-spin" />
              </button>

            </div>

            <!-- Selector de base de datos -->
            <transition enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 -translate-y-1">
              <div v-if="requiresDatabaseQuery && dbConnections && dbConnections.length > 0"
                class="flex items-center space-x-3 mt-2 bg-gray-50 p-2 rounded-md shadow-sm">
                <div class="flex-grow flex items-center space-x-2">
                  <label for="databaseConnection" class="text-xs font-medium text-gray-500">
                    {{ $t('chat.requiresDatabaseQuery') }}:
                  </label>
                  <select v-if="requiresDatabaseQuery" v-model="selectedDatabaseConnectionId" id="databaseConnection"
                    class="flex-grow min-w-0 p-1.5 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500">
                    <option v-for="connection in dbConnections" :key="connection.id" :value="connection.id">
                      {{ connection.name }}
                    </option>
                  </select>
                </div>
              </div>
            </transition>

          </form>
        </div>


      </div>
    </div>

    <!-- Modales -->
    <KnowledgeBaseModal v-if="showKnowledgeBase" @close="showKnowledgeBase = false" />
    <UserProfileModal v-if="showUserProfile" @close="showUserProfile = false" />
    <AccountManagementModal v-if="showAccountManagement" @close="showAccountManagement = false" />
    <UserManagementModal v-if="showUserManagement" @close="showUserManagement = false" />
    <AccountUsageModal v-if="showAccountUsage" @close="showAccountUsage = false" />
    <PricingPlanModal v-if="showPricingPlans" @close="showPricingPlans = false" />
    <AIProviderConfigurationModal v-if="showAIProviderConfiguration" @close="showAIProviderConfiguration = false" />
    <LegalModal :visible="showLegalModal" :title="modalTitle" :content="modalContent" @close="showLegalModal = false" />

    <!-- PWA Installation Banner -->
    <PwaInstallBanner :hasBottomNav="true" />

    <!-- Modal de advertencia de base de conocimiento sin datos -->
    <KnowledgeBaseWarningModal v-if="showKnowledgeBaseWarning" @close="showKnowledgeBaseWarning = false"
      @sendAnyway="sendMessageAnyway" @addKnowledge="openKnowledgeBase" />

  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { Archive, User, LogOut, Send, Loader, Building2, Users, Gauge, Menu, Coins, Bot, ChevronLeft, ChevronRight, Edit2Icon, TrashIcon, CheckIcon, CopyIcon, MessagesSquare, SquarePlus, Database, X } from 'lucide-vue-next';
import { askQuestion, getConversations, getConversation, renameConversation, deleteConversation, listDatabaseConnections, updateActivity, listFiles } from '../services/api';
import KnowledgeBaseModal from './KnowledgeBaseModal.vue';
import UserProfileModal from './UserProfileModal.vue';
import AccountManagementModal from './AccountManagementModal.vue';
import UserManagementModal from './UserManagementModal.vue';
import AccountUsageModal from './AccountUsageModal.vue';
import PricingPlanModal from './PricingPlanModal.vue';
import AIProviderConfigurationModal from './AIProviderConfigurationModal.vue';
import KnowledgeBaseWarningModal from './KnowledgeBaseWarningModal.vue';
import LegalModal from './LegalModal.vue';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { getCurrentUserRoles, clearUserInfo, isAuthenticated } from '../utils/auth';
import { useHead } from '@vueuse/head'
import { onClickOutside } from '@vueuse/core'
import { useNotification } from '@/plugins/notificationPlugin';
import { useI18n } from 'vue-i18n';
import PwaInstallBanner from './PwaInstallBanner.vue';

const { t, locale } = useI18n();

function changeLanguage(lang) {
  locale.value = lang;
  localStorage.setItem('selectedLanguage', lang);
}

const userRoles = computed(() => getCurrentUserRoles());
const isSysAdmin = computed(() => userRoles.value.includes('SysAdmin'));

const { showSuccess, showError } = useNotification();

const pageTitle = computed(() => t('chat.meta.title'));
const pageDescription = computed(() => t('chat.meta.description'));

useHead({
  title: pageTitle.value,
  meta: [
    { name: 'description', content: pageDescription.value, },
    { property: 'og:title', content: pageTitle.value, },
    { property: 'og:description', content: pageDescription.value, },
    { name: 'twitter:title', content: pageTitle.value, },
    { name: 'twitter:description', content: pageDescription.value, },
  ],
});

const messages = ref([]);
const userInput = ref('');
const isLoading = ref(false);
const router = useRouter();
const chatContainer = ref(null);
const showKnowledgeBase = ref(false);
const showUserProfile = ref(false);
const showAccountManagement = ref(false);
const showUserManagement = ref(false);
const  showAccountUsage = ref(false);
const showPricingPlans = ref(false);
const showAIProviderConfiguration = ref(false);
const conversationId = ref(null);
const isMenuOpen = ref(false);
const menuRef = ref(null);
const conversations = ref([]);
const showSidebar = ref(true);
const copiedMessageId = ref(null);
const showLegalModal = ref(false);
const modalTitle = ref('');
const modalContent = ref('');
const requiresDatabaseQuery = ref(false);
const selectedDatabaseConnectionId = ref('');
const dbConnections = ref([]);
const showKnowledgeBaseWarning = ref(false);
const pendingMessage = ref('');
const hasCheckedFiles = ref(false); 

const isAdmin = computed(() => {
  const roles = getCurrentUserRoles();
  return roles.includes('AccountAdmin') || roles.includes('SysAdmin');
});

const sortedConversations = computed(() => {
  return [...conversations.value].sort((a, b) => new Date(b.modified) - new Date(a.modified));
});

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const toggleSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const toggleDatabaseOptions = () => {
  requiresDatabaseQuery.value = !requiresDatabaseQuery.value;
  if (!requiresDatabaseQuery.value) {
    requiresDatabaseQuery.value = false;
  } else {
    requiresDatabaseQuery.value = true;
    setDefaultDatabaseConnection();
  }
};

onClickOutside(menuRef, () => {
  isMenuOpen.value = false;
});

const adjustTextareaHeight = (event) => {
  const textarea = event.target;
  textarea.style.height = 'auto';
  textarea.style.height = `${textarea.scrollHeight}px`;
};

const handleEnterKey = (event) => {
  if (event.shiftKey) {
    return;
  }
  event.preventDefault();
  sendMessage();
};

const isThinking = ref(false);
const thinkingMessage = ref(t('chat.thinking'));

const validateDatabaseQuery = () => {
  if (requiresDatabaseQuery.value && (!selectedDatabaseConnectionId.value || selectedDatabaseConnectionId.value === '')) {
    showError(t('chat.selectDatabase'));
    return false;
  }
  return true;
};

const setDefaultDatabaseConnection = () => {
  if (dbConnections.value.length > 0) {
    selectedDatabaseConnectionId.value = dbConnections.value[0].id;
  }
};

const sendMessage = async () => {
  if (userInput.value.trim() === '') return;
  if (!validateDatabaseQuery()) return;
  
  const userMessage = userInput.value;
  
  if (!hasCheckedFiles.value && !requiresDatabaseQuery.value) {
    try {
      const filesResponse = await listFiles();
      if (filesResponse.data.length === 0) {
        console.log('No files found');
        pendingMessage.value = userMessage;
        showKnowledgeBaseWarning.value = true;
        hasCheckedFiles.value = true;
        return;
      }
      hasCheckedFiles.value = true;
    } catch (error) {
      showError(t('chat.errorCheckingFiles'));
      return;
    }
  }
  
  await sendMessageToAPI(userMessage);
};

const sendMessageAnyway = async () => {
  showKnowledgeBaseWarning.value = false;
  await sendMessageToAPI(pendingMessage.value);
  pendingMessage.value = '';
};

const openKnowledgeBase = () => {
  showKnowledgeBaseWarning.value = false;
  showKnowledgeBase.value = true;
};

const sendMessageToAPI = async (message) => {
  messages.value.push({ text: message, isUser: true, id: Date.now() });
  userInput.value = '';
  isLoading.value = true;
  isThinking.value = true;
  thinkingMessage.value = t('chat.thinking');
  setTimeout(() => {
    thinkingMessage.value = t('chat.writing');
  }, Math.floor(Math.random() * (4000 - 2000 + 1)) + 2000);
  scrollToBottom();

  try {
    const response = await askQuestion({
      question: message,
      conversationId: conversationId.value,
      requiresDatabaseQuery: requiresDatabaseQuery.value,
      databaseConnectionId: requiresDatabaseQuery.value ? selectedDatabaseConnectionId.value : null
    });

    isThinking.value = false;
    messages.value.push({ text: response.data.answer, isUser: false, id: Date.now() });
    
    if (response.data.conversationId) {
      conversationId.value = response.data.conversationId;
      await loadConversations();
    }
  } catch (error) {
    isThinking.value = false;
    showError(error.detail || t('genericError'));
  } finally {
    isLoading.value = false;
    scrollToBottom();
  }
};

const loadDatabaseConnections = async () => {
  try {
    const response = await listDatabaseConnections();
    dbConnections.value = response.data;
    setDefaultDatabaseConnection();
  } catch (error) {
    showError(t('chat.errorLoadingDatabases'));
  }
};

onMounted(async () => {
  showSidebar.value = window.innerWidth >= 768;

  if (!isAuthenticated()) {
    router.push('/login');
  } else {
    try { await updateActivity(); } catch (error) { console.error('Error updating activity', error); }
    try { await loadConversations(); } catch (error) { console.error('Error loading conversations', error); }
    try { await loadDatabaseConnections(); } catch (error) { console.error('Error loading database connections', error); }

    messages.value.push({ text: t('chat.welcome'), isUser: false, id: Date.now() });
    scrollToBottom();
  }
});

const scrollToBottom = () => {
  nextTick(() => {
    if (chatContainer.value) {
      chatContainer.value.scrollTo({
        top: chatContainer.value.scrollHeight,
        behavior: 'smooth'
      });
    }
  });
};

const copyToClipboard = (text, messageId) => {
  navigator.clipboard.writeText(text).then(() => {
    copiedMessageId.value = messageId;
    setTimeout(() => {
      copiedMessageId.value = null;
    }, 600);
  });
};

const handleLogout = async () => {
  clearUserInfo();
  router.push('/login');
};

const renderMarkdown = (text) => {
    // Convertir Markdown a HTML sin renderer personalizado
    let html = marked(text);

    // Añadir target="_blank" a todos los enlaces
    html = html.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ');

    // Envolver cada bloque de código en `.code-block` e incluir el nombre del lenguaje
    html = html.replace(
        /<pre><code class="language-(.*?)">/g,
        `<div class="code-block"><span class="language-label">$1</span><button class="copy-icon">${t('chat.copyCode')}</button><pre><code class="language-$1">`
    ).replace(
        /<\/code><\/pre>/g,
        `</code></pre></div>`
    );

    // Sanitizar el HTML para seguridad
    return DOMPurify.sanitize(html, { ALLOWED_ATTR: ['target', 'href', 'rel', 'style', 'class'] });
};

const addCopyButtons = () => {
    const codeBlocks = document.querySelectorAll('.code-block pre code');
    codeBlocks.forEach((codeBlock) => {
        const wrapper = codeBlock.closest('.code-block');
        if (wrapper) {
            const copyButton = wrapper.querySelector('.copy-icon');
            if (copyButton) {
                copyButton.addEventListener('click', () => {
                    navigator.clipboard.writeText(codeBlock.innerText)
                        .catch((err) => console.error('Error:', err));
                });
            }
        }
    });
};

import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css';

const addSyntaxHighlighting = () => {
    document.querySelectorAll('pre code').forEach((block) => {
        hljs.highlightElement(block);
    });
};

watch(messages, async () => {
    await nextTick();
    addSyntaxHighlighting();
    addCopyButtons();
});

const startNewConversation = () => {
  messages.value = [];
  conversationId.value = null;
  userInput.value = '';
  isThinking.value = false;
  thinkingMessage.value = '';
  requiresDatabaseQuery.value = false;
  
  messages.value.push({
    text: t('chat.welcomeAgain'),
    isUser: false,
    id: Date.now()
  });
  scrollToBottom();
  
  if (window.innerWidth < 768) {
    showSidebar.value = false;
  }
};

const loadConversations = async () => {
  try {
    const response = await getConversations();
    conversations.value = response.data; 
  } catch (error) {
    showError(t('genericError'));
  }
};

const loadConversation = async (id) => {
  try {
    const conversation = await getConversation(id);
    requiresDatabaseQuery.value = false;
    messages.value = conversation.data.map(m => ({
      text: m.content,
      isUser: m.role === 'user',
      id: Date.now() + Math.random()
    }));
    conversationId.value = id;
    scrollToBottom();
    if (window.innerWidth < 768) {
      showSidebar.value = false;
    }
  } catch (error) {
    if (error.status === 404) {
      showError(t('chat.conversationNotFound'));
    } else {
    showError(t('genericError'));
    }
  }
};

const editNameInput = ref(null);
const editingConversationId = ref(null);
const editingConversationName = ref('');
const showDeleteConfirm = ref(false);
const conversationToDeleteId = ref(null);

const startEditingConversation = (conversation) => {
  editingConversationId.value = conversation.id;
  editingConversationName.value = conversation.title;
  nextTick(() => {
    if (editNameInput) {
      editNameInput.value.focus();
    }
  });
};

const saveConversationName = async (conversation) => {
  if (editingConversationName.value.trim() !== '' && editingConversationName.value !== conversation.name) {
    try {
      await renameConversation(conversation.id, editingConversationName.value);
      await loadConversations();
    } catch (error) {
      showError('Error al renombrar la conversación');
    }
  }
  editingConversationId.value = null;
};

const confirmDeleteConversation = (id) => {
  conversationToDeleteId.value = id;
  showDeleteConfirm.value = true;
};

const deleteConversationConfirmed = async () => {
  if (conversationToDeleteId.value) {
    try {
      await deleteConversation(conversationToDeleteId.value);
      await loadConversations();
      if (conversationId.value === conversationToDeleteId.value) {
        messages.value = [];
        conversationId.value = null;
      }
    } catch (error) {
      showError('Error al eliminar la conversación');
    }
  }
  showDeleteConfirm.value = false;
  conversationToDeleteId.value = null;
};

watch(messages, () => {
  scrollToBottom();
});

const loadConditions = async () => {
  try {
    const response = await fetch(`/legal/conditions-${locale.value}.md`);
    if (response.ok) {
      const rawContent = await response.text();
      modalContent.value = DOMPurify.sanitize(marked(rawContent));
    } else {
      modalContent.value = 'No se pudo cargar la nota legal.';
    }
  } catch (error) {
    modalContent.value = 'Error al cargar la nota legal.';
  }
  showLegalModal.value = true;
};
</script>