<template>
    <div class="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">

        <DynamicBackground />

        <div class="w-full max-w-md z-10">
            <div class="bg-white bg-opacity-85 rounded-lg shadow-xl overflow-hidden">
                <!-- Cabecera del formulario -->
                <div class="bg-indigo-600 p-5 text-white">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center space-x-2">
                            <img src="@/assets/img/logo.svg" alt="Aignis" class="h-8 w-auto" />
                            <h1 class="text-xl font-bold">{{ $t('appName') }}</h1>
                        </div>
                        <!-- Botones de selección de idioma -->
                        <div class="flex space-x-2">
                            <button @click="changeLanguage('en')"
                                class="text-xs px-2 py-1 bg-indigo-700 text-white rounded hover:bg-indigo-800 transition duration-300">
                                EN
                            </button>
                            <button @click="changeLanguage('es')"
                                class="text-xs px-2 py-1 bg-indigo-700 text-white rounded hover:bg-indigo-800 transition duration-300">
                                ES
                            </button>
                        </div>
                    </div>
                </div>
                <div class="text-center p-6 pb-16">
                    <svg class="mx-auto h-12 w-12 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <h2 class="mt-4 text-2xl font-bold text-gray-900">{{ $t('successPage.title') }}</h2>
                    <p class="mt-2 text-sm text-gray-600" v-html="$t('successPage.text')"></p>
                </div>
                <div
                    class="px-6 md:px-8 py-4 bg-gray-50 border-t border-gray-100 flex flex-col sm:flex-row justify-between items-center">
                    <p class="text-xs text-gray-500">{{ $t('successPage.credentialsReceived') }}</p>
                    <a href="/login" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">{{
                        $t('successPage.cta') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n';
import DynamicBackground from './DynamicBackground.vue';

const { t, locale } = useI18n();

function changeLanguage(lang) {
  locale.value = lang;
  localStorage.setItem('selectedLanguage', lang);
}

const pageTitle = computed(() => t('successPage.title'));

useHead({
  title: pageTitle.value,
});
</script>