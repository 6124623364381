<template>
  <div>
    <transition name="slide-up">
      <div v-if="showInstallPrompt"
        :class="[
          'fixed left-0 right-0 p-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white shadow-lg z-50',
          hasBottomNav ? 'bottom-20' : 'bottom-0'
        ]">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <img src="@/assets/img/logo.svg" alt="App Logo" class="h-8 w-8" />
            <div>
              <h3 class="font-bold text-lg text-left">{{ $t('pwa.installTitle') }}</h3>
              <p class="text-sm opacity-90 text-left">{{ $t('pwa.installDescription') }}</p>
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <button @click="installPwa"
              class="px-4 py-2 bg-white text-indigo-600 rounded-full font-medium hover:bg-opacity-90 transition duration-300">
              {{ $t('pwa.install') }}
            </button>
            <button @click="dismissPrompt"
              class="p-2 hover:bg-white hover:bg-opacity-20 rounded-full transition duration-300">
              <XIcon class="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="showIOSInstructions"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 text-left">
        <div class="bg-white rounded-lg shadow-xl max-w-md w-full">
          <div class="p-6">
            <div class="flex justify-between items-center mb-4">
              <h3 class="text-2xl font-bold text-gray-900">{{ $t('pwa.iosInstructionsTitle') }}</h3>
              <button @click="closeIOSInstructions" class="text-gray-400 hover:text-gray-500">
                <XIcon class="h-6 w-6" />
              </button>
            </div>
            <div class="space-y-4">
              <div class="flex items-center space-x-4">
                <div class="flex-shrink-0 w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center">
                  <Share class="h-5 w-5 text-indigo-600" />
                </div>
                <p class="text-gray-700">{{ $t('pwa.iosStep1') }}</p>
              </div>
              <div class="flex items-center space-x-4">
                <div class="flex-shrink-0 w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center">
                  <SquarePlus class="h-5 w-5 text-indigo-600" />
                </div>
                <p class="text-gray-700">{{ $t('pwa.iosStep2') }}</p>
              </div>
              <div class="flex items-center space-x-4">
                <div class="flex-shrink-0 w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center">
                  <Check class="h-5 w-5 text-indigo-600" />
                </div>
                <p class="text-gray-700">{{ $t('pwa.iosStep3') }}</p>
              </div>
            </div>
            <button @click="dismissPrompt"
              class="mt-6 w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300">
              {{ $t('pwa.gotIt') }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { XIcon, Share, SquarePlus, Check } from 'lucide-vue-next';

const props = defineProps({
  hasBottomNav: {
    type: Boolean,
    default: false
  }
});

const { t } = useI18n();

const showInstallPrompt = ref(false);
const showIOSInstructions = ref(false);
let deferredPrompt = null;

const installPwa = () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      deferredPrompt = null;
      showInstallPrompt.value = false;
    });
  } else {
    showIOSInstructions.value = true;
  }
};

const dismissPrompt = () => {
  showInstallPrompt.value = false;
  closeIOSInstructions();
  localStorage.setItem('pwaPromptDismissed', Date.now().toString());
};

const closeIOSInstructions = () => {
  showIOSInstructions.value = false;
  localStorage.setItem('pwaPromptDismissed', Date.now().toString());
};

const handleBeforeInstallPrompt = (e) => {
  e.preventDefault();
  deferredPrompt = e;
  showInstallPrompt.value = true;
};

const checkIfShouldShowPrompt = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;
  const lastDismissed = localStorage.getItem('pwaPromptDismissed');
  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
  const shouldShowPrompt = !isInStandaloneMode && (!lastDismissed || Date.now() - parseInt(lastDismissed) > thirtyDaysInMs);

  if (isIOS && shouldShowPrompt) {
    showInstallPrompt.value = true;
  }
};

onMounted(() => {
  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  checkIfShouldShowPrompt();
});

onUnmounted(() => {
  window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
});
</script>

<style scoped>
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease-out;
}
.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>