<template>
  <div class="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen">
    <!-- Navegación -->
    <nav class="bg-white shadow-md fixed w-full z-50">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <a href="javascript:void(0)" @click="scrollToTop">
                <img src="@/assets/img/logo-inverted.svg" alt="Aignis" class="h-8 w-auto" />
              </a>
              <a href="javascript:void(0)" @click="scrollToTop">
                <h2 class="text-xl font-bold text-indigo-600" style="margin-left: 10px;">{{ $t('appName') }}</h2>
              </a>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
              <a href="#whois"
                class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                {{ $t('home.menu.about') }}
              </a>
              <a href="#features"
                class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                {{ $t('home.menu.features') }}
              </a>
              <a href="#pricing"
                class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                {{ $t('home.menu.pricing') }}
              </a>
              <a href="#contact"
                class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                {{ $t('home.menu.contact') }}
              </a>
            </div>
          </div>
          <div class="ml-6 flex items-center">
            <router-link to="/login"
              class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out">
              {{ $t('home.menu.login') }}
            </router-link>
          </div>
        </div>
      </div>
    </nav>

    <!-- Hero Section -->
    <div class="relative bg-white overflow-hidden pt-16">
      <div class="max-w-7xl mx-auto">
        <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div class="text-center lg:text-left balanced-text">
              <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span class="xl:inline" v-html="$t('home.hero.title')"></span>
                <span class="text-indigo-600 xl:inline">{{ $t('appName') }}</span>
              </h1>
              <p
                class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {{ $t('home.hero.subtitle1') }}
              </p>
              <p
                class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {{ $t('home.hero.subtitle2') }}
              </p>
              <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start relative z-30">
                <div class="rounded-md shadow">
                  <a href="#pricing"
                    class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                    {{ $t('home.hero.cta') }}
                  </a>
                </div>
                <div class="mt-3 sm:mt-0 sm:ml-3">
                  <a href="#whois"
                    class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                    {{ $t('home.hero.learnMore') }}
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 z-40">
        <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="/homeAignis.jpg" alt="Aignis">
      </div>
    </div>

    <!-- Quién es Aignis? -->
    <div id="whois" class="bg-gray-50 py-12">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">{{ $t('home.sections.aboutTitle')
            }}</h2>
          <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            {{ $t('home.sections.aboutContent') }}
          </p>
        </div>
      </div>
    </div>

    <!-- Características -->
    <div id="features" class="py-12 bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">{{
            $t('home.sections.featuresTitle') }}</h2>
          <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {{ $t('home.sections.featuresSubtitle') }}
          </p>
          <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto" v-html="$t('home.sections.featuresContent')"></p>
        </div>
        <div class="mt-10">
          <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <Archive class="h-6 w-6" />
                </div>
                <p class="ml-16 text-lg text-left leading-6 font-medium text-gray-900">{{
                  $t('home.features.feature1.title') }}</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-left text-gray-500"
                v-html="$t('home.features.feature1.description')"></dd>
            </div>
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <Lock class="h-6 w-6" />
                </div>
                <p class="ml-16 text-lg text-left leading-6 font-medium text-gray-900">{{
                  $t('home.features.feature2.title') }}</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-left text-gray-500"
                v-html="$t('home.features.feature2.description')"></dd>
            </div>
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <Link class="h-6 w-6" />
                </div>
                <p class="ml-16 text-lg text-left leading-6 font-medium text-gray-900">{{
                  $t('home.features.feature3.title') }}</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-left text-gray-500"
                v-html="$t('home.features.feature3.description')"></dd>
            </div>
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <BarChart class="h-6 w-6" />
                </div>
                <p class="ml-16 text-lg text-left leading-6 font-medium text-gray-900">{{
                  $t('home.features.feature4.title') }}</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-left text-gray-500"
                v-html="$t('home.features.feature4.description')"></dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <!-- Precios -->
    <div id="pricing" class="bg-gray-100 py-12">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="sm:text-center">
          <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {{ $t('home.pricing.title') }}
          </h2>
          <p class="mt-4 text-lg text-gray-500">
            {{ $t('home.pricing.subtitle') }}
          </p>
        </div>
        <div
          class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-2 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
          <div class="bg-white border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
            <div class="p-6 text-center">
              <div class="flex justify-center mb-4">
                <Flame class="h-8 w-8 text-indigo-600" />
              </div>
              <h2 class="text-xl leading-6 font-medium text-gray-900">{{ $t('home.pricing.plans.tier0.name') }}</h2>
              <p class="mt-4 text-sm text-gray-500">{{ $t('home.pricing.plans.tier0.description') }}</p>
              <p class="mt-8">
                <span class="text-4xl font-extrabold text-gray-900">{{ $t('home.pricing.plans.tier0.price') }}</span>
              </p>
              <a href="/signup?planId=1"
                class="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700">
                {{ $t('home.pricing.plans.tier0.cta') }}
              </a>
            </div>
            <div class="pt-6 pb-8 px-4">
              <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase text-center">{{
                $t('home.pricing.plans.whatIncludes') }}</h3>
              <ul class="mt-6 space-y-4">
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1  1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier0.includes.knowledgeBase')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{
                    $t('home.pricing.plans.tier0.includes.conversationTokens') }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier0.includes.languageModel')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier0.includes.users')
                    }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="bg-white border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
            <div class="p-6 text-center">
              <div class="flex justify-center mb-4">
                <Flame class="h-8 w-8 text-indigo-500" />
                <Flame class="h-8 w-8 text-indigo-600" />
              </div>
              <h2 class="text-xl leading-6 font-medium text-gray-900">{{ $t('home.pricing.plans.tier1.name') }}</h2>
              <p class="mt-4 text-sm text-gray-500">{{ $t('home.pricing.plans.tier1.description') }}</p>
              <p class="mt-8">
                <span class="text-4xl font-extrabold text-gray-900">{{ $t('home.pricing.plans.tier1.price') }}</span>
                <span class="text-base font-medium text-gray-500"> {{ $t('home.pricing.plans.perMonth') }}</span>
              </p>
              <a href="/signup?planId=2"
                class="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700">
                {{ $t('home.pricing.plans.tier1.cta') }}
              </a>
            </div>
            <div class="pt-6 pb-8 px-4">
              <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase text-center">{{
                $t('home.pricing.plans.whatIncludes') }}</h3>
              <ul class="mt-6 space-y-4">
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1  1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier1.includes.knowledgeBase')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{
                    $t('home.pricing.plans.tier1.includes.conversationTokens') }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier1.includes.languageModel')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier1.includes.users')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier1.includes.support')
                    }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="bg-white border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
            <div class="p-6 text-center">
              <div class="flex justify-center mb-4">
                <Flame class="h-8 w-8 text-indigo-400" />
                <Flame class="h-8 w-8 text-indigo-500" />
                <Flame class="h-8 w-8 text-indigo-600" />
              </div>
              <h2 class="text-xl leading-6 font-medium text-gray-900">{{ $t('home.pricing.plans.tier2.name') }}</h2>
              <p class="mt-4 text-sm text-gray-500">{{ $t('home.pricing.plans.tier2.description') }}</p>
              <p class="mt-8">
                <span class="text-4xl font-extrabold text-gray-900">{{ $t('home.pricing.plans.tier2.price') }}</span>
                <span class="text-base font-medium text-gray-500"> {{ $t('home.pricing.plans.perMonth') }}</span>
              </p>
              <a href="/signup?planId=3"
                class="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700">
                {{ $t('home.pricing.plans.tier2.cta') }}
              </a>
            </div>
            <div class="pt-6 pb-8 px-4">
              <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase text-center">{{
                $t('home.pricing.plans.whatIncludes') }}</h3>
              <ul class="mt-6 space-y-4">
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1  1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier2.includes.knowledgeBase')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{
                    $t('home.pricing.plans.tier2.includes.conversationTokens') }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier2.includes.languageModel')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier2.includes.users')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier2.includes.support')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier2.includes.integration')
                    }}
                    <a href="https://api.aignis.net/api-docs" target="_blank" rel="noopener noreferrer"
                      class="text-xs text-gray-500 text-left hover:text-indigo-600 transition-colors duration-300 italic">
                      (doc.)
                    </a></span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier2.includes.databases')
                    }} **</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="bg-white border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
            <div class="p-6 text-center">
              <div class="flex justify-center mb-4">
                <Flame class="h-8 w-8 text-indigo-400" />
                <Flame class="h-8 w-8 text-indigo-500" />
                <Flame class="h-8 w-8 text-indigo-600" />
                <Flame class="h-8 w-8 text-indigo-700" />
              </div>
              <h2 class="text-xl leading-6 font-medium text-gray-900">{{ $t('home.pricing.plans.tier3.name') }}</h2>
              <p class="mt-4 text-sm text-gray-500">{{ $t('home.pricing.plans.tier3.description') }}</p>
              <p class="mt-8">
                <span class="text-4xl font-extrabold text-gray-900">{{ $t('home.pricing.plans.tier3.price') }}</span>
                <!-- <span class="text-base font-medium text-gray-500"> {{ $t('home.pricing.plans.from') }}</span>
                <span class="text-4xl font-extrabold text-gray-900">{{ $t('home.pricing.plans.tier3.priceDisabled') }}</span>
                <span class="text-base font-medium text-gray-500"> {{ $t('home.pricing.plans.perMonth') }}</span> -->
              </p>
              <a href="#contact"
                class="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700">
                {{ $t('home.pricing.plans.tier3.cta') }}
              </a>
            </div>
            <div class="pt-6 pb-8 px-4">
              <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase text-center">{{
                $t('home.pricing.plans.whatIncludes') }}</h3>
              <ul class="mt-6 space-y-4">
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1  1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier3.includes.knowledgeBase')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{
                    $t('home.pricing.plans.tier3.includes.conversationTokens') }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier3.includes.languageModel')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier3.includes.users')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier3.includes.support')
                    }}</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier2.includes.integration')
                    }}
                    <a href="https://api.aignis.net/api-docs" target="_blank" rel="noopener noreferrer"
                      class="text-xs text-gray-500 text-left hover:text-indigo-600 transition-colors duration-300 italic">
                      (doc.)
                    </a></span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier3.includes.databases')
                    }} **</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="text-sm text-gray-500 text-left">{{ $t('home.pricing.plans.tier3.includes.localSetup')
                    }}</span>
                </li>
              </ul>
            </div>
          </div>

        </div>
        <div class="mt-8 text-center">
          <p class="text-sm text-gray-500 italic balanced-text" v-html="$t('home.pricing.notes1')"></p>
          <p class="text-sm text-gray-500 italic balanced-text mt-4" v-html="$t('home.pricing.notes2')"></p>
          <p class="text-sm text-gray-500 italic balanced-text mt-4" v-html="$t('home.pricing.notes3')"></p>
        </div>
      </div>
    </div>

    <!-- Contacto -->
    <div id="contact" class="bg-white py-12">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {{ $t('home.contact.title') }}
          </h2>
          <p class="mt-4 max-w-2xl text-xl text-gray-600 lg:mx-auto">
            {{ $t('home.contact.subtitle') }}
          </p>
        </div>
        <div class="mt-10 flex justify-center">
          <div class="bg-gray-100 shadow-lg rounded-lg p-8 sm:p-10 lg:p-12">
            <p class="text-xl font-semibold text-gray-700">{{ $t('home.contact.emailPrompt') }}</p>
            <p class="mt-4 text-3xl font-bold text-indigo-600">
              <a href="mailto:info@aignis.net" class="hover:underline">info@aignis.net</a>
            </p>
            <p class="mt-2 text-sm text-gray-500">{{ $t('home.contact.responseTime') }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <footer class="bg-indigo-600">
      <div class="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col items-center space-y-8 md:flex-row md:justify-between md:space-y-0">
          <!-- Logo and Copyright -->
          <div class="flex items-center space-x-3 order-1 md:order-1">
            <img src="@/assets/img/logo.svg" alt="Aignis" class="h-8 w-auto" />
            <p class="text-sm text-white">&copy; {{ currentYear }} {{ $t('home.footer.copyright') }}</p>
          </div>

          <!-- Language Selector -->
          <div class="flex space-x-2 order-2 md:order-3">
            <button @click="changeLanguage('en')"
              class="text-xs px-3 py-1.5 bg-indigo-500 text-white rounded-full hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 transition duration-300 font-medium">
              EN
            </button>
            <button @click="changeLanguage('es')"
              class="text-xs px-3 py-1.5 bg-indigo-500 text-white rounded-full hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 transition duration-300 font-medium">
              ES
            </button>
          </div>

          <!-- Footer Links -->
          <nav class="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-6 order-3 md:order-2">
            <a href="#" @click.prevent="loadConditions"
              class="text-sm text-white hover:text-gray-200 text-center md:text-left">
              {{ $t('home.footer.terms') }}
            </a>
            <a href="#" @click.prevent="loadLegalNotice"
              class="text-sm text-white hover:text-gray-200 text-center md:text-left">
              {{ $t('home.footer.legalNotice') }}
            </a>
            <a href="#" @click.prevent="loadPrivacy"
              class="text-sm text-white hover:text-gray-200 text-center md:text-left">
              {{ $t('home.footer.privacyPolicy') }}
            </a>
          </nav>
        </div>
      </div>
    </footer>

    <!-- Modal para la textos legales -->
    <LegalModal :visible="showLegalModal" :content="modalContent" @close="showLegalModal = false" />

  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { Flame, Archive, Link, Lock, BarChart } from 'lucide-vue-next';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import LegalModal from './LegalModal.vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const currentYear = new Date().getFullYear()

const pageTitle = computed(() => t('home.meta.title'));
const pageDescription = computed(() => t('home.meta.description'));

useHead({
  title: pageTitle.value,
  meta: [
    {
      name: 'description',
      content: pageDescription.value,
    },
    {
      property: 'og:title',
      content: pageTitle.value,
    },
    {
      property: 'og:description',
      content: pageDescription.value,
    },
    {
      name: 'twitter:title',
      content: pageTitle.value,
    },
    {
      name: 'twitter:description',
      content: pageDescription.value,
    },
  ],
});

onMounted(() => {
  const links = document.querySelectorAll('a[href^="#"]')
  links.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      const targetId = link.getAttribute('href')
      if (targetId !== '#') {
        scrollToElement(targetId)
      }
    })
  })

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('section') === 'pricing') {
    scrollWithDelay('#pricing');
}})

const scrollWithDelay = (targetId) => {
  setTimeout(() => {
    scrollToElement(targetId);
  }, 200);
};

const scrollToElement = (targetId) => {
  const targetElement = document.querySelector(targetId);
  const appElement = document.querySelector('#app');
  if (targetElement && appElement) {
    const elementPosition = targetElement.getBoundingClientRect().top + appElement.scrollTop;
    const offsetPosition = elementPosition - 50;
    appElement.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const showLegalModal = ref(false);
const modalContent = ref('');

const loadLegalNotice = async () => {
  try {
    const response = await fetch(`/legal/legal-${locale.value}.md`);
    if (response.ok) {
      const rawContent = await response.text();
      modalContent.value = DOMPurify.sanitize(marked(rawContent));
    } else {
      modalContent.value = 'No se pudo cargar la nota legal.';
    }
  } catch (error) {
    modalContent.value = 'Error al cargar la nota legal.';
  }
  showLegalModal.value = true; 
};

const loadPrivacy = async () => {
  try {
    const response = await fetch(`/legal/privacy-${locale.value}.md`);
    if (response.ok) {
      const rawContent = await response.text();
      modalContent.value = DOMPurify.sanitize(marked(rawContent));
    } else {
      modalContent.value = 'No se pudo cargar la poítica de privacidad.';
    }
  } catch (error) {
    modalContent.value = 'Error al cargar la política de privacidad.';
  }
  showLegalModal.value = true; 
};

const loadConditions = async () => {
  try {
    const response = await fetch(`/legal/conditions-${locale.value}.md`);
    if (response.ok) {
      const rawContent = await response.text();
      modalContent.value = DOMPurify.sanitize(marked(rawContent));
    } else {
      modalContent.value = 'No se pudo cargar las condiciones de uso.';
    }
  } catch (error) {
    modalContent.value = 'Error al cargar las condiciones de uso.';
  }
  showLegalModal.value = true; 
};

function changeLanguage(lang) {
  locale.value = lang;
  localStorage.setItem('selectedLanguage', lang);
}
</script>
