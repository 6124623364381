import { createApp, watch } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './config/i18n';
import notificationPlugin from './plugins/notificationPlugin'
import './assets/css/main.css'

const app = createApp(App)
const head = createHead()

// Observa cambios en el idioma para actualizar el atributo lang
watch(
    () => i18n.global.locale,
    (newLang) => {
      document.documentElement.setAttribute('lang', newLang);
    }
  );

app.use(store).use(router).use(head).use(i18n).use(notificationPlugin).mount('#app')
