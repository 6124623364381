import { createI18n } from 'vue-i18n';
import en from '../locales/en';
import es from '../locales/es';

// Configura los mensajes de traducción
const messages = {
  en,
  es,
};

// Verifica si hay un idioma guardado en localStorage
const savedLanguage = localStorage.getItem('selectedLanguage');

// Detecta el idioma del navegador si no hay uno guardado
const browserLanguage = navigator.language || navigator.userLanguage;
const defaultLanguage = browserLanguage.split('-')[0]; // Obtiene el código de idioma base, e.g., 'en'

// Determina el idioma inicial: guardado, del navegador o inglés por defecto
const initialLanguage = savedLanguage || (messages[defaultLanguage] ? defaultLanguage : 'en');

// Crea la instancia de i18n con el idioma inicial
const i18n = createI18n({
  locale: initialLanguage, // Usa el idioma guardado o el detectado
  fallbackLocale: 'en', // Idioma de respaldo si el idioma detectado no está disponible
  messages,
});

// Establece el atributo lang en el <html> inicialmente
document.documentElement.setAttribute('lang', initialLanguage);

export default i18n;
