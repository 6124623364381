<template>
  <div>
    <router-view></router-view>
    <Notification 
      :show="notification.show"
      :message="notification.message"
      :type="notification.type"
    />
  </div>
</template>

<script setup>
import { useNotification } from './plugins/notificationPlugin';
import Notification from './components/Notification.vue';

const { notification } = useNotification();
</script>
