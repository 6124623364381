<template>
    <transition name="fade">
      <div v-if="show" :class="notificationClass" class="fixed top-4 right-4 px-4 py-2 rounded-md shadow-lg z-50">
        {{ message }}
      </div>
    </transition>
  </template>
  
  <script setup>
  import { computed } from 'vue';
  
  const props = defineProps({
    type: {
      type: String,
      default: 'success',
      validator: (value) => ['success', 'error', 'info', 'warning'].includes(value)
    },
    message: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  });
  
  const notificationClass = computed(() => {
    switch (props.type) {
      case 'success':
        return 'bg-green-500 text-white';
      case 'error':
        return 'bg-red-500 text-white';
      case 'info':
        return 'bg-blue-500 text-white';
      case 'warning':
        return 'bg-yellow-500 text-black';
      default:
        return 'bg-gray-500 text-white';
    }
  });
  </script>
  