<template>
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50">
    <div class="w-full max-w-6xl bg-white rounded-lg shadow-xl overflow-hidden flex flex-col">
      <!-- Cabecera del popup -->
      <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
        <div class="flex items-center space-x-2">
          <img src="@/assets/img/logo.svg" alt="Aignis" class="h-6 w-auto" />
          <h3 class="text-xl font-bold">{{ $t('accountUsage.title') }}</h3>
        </div>
        <button @click="emit('close')" class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
          <XIcon class="h-6 w-6" />
        </button>
      </div>

      <!-- Contenido del popup -->
      <div class="p-6 bg-gray-50 overflow-y-auto" style="max-height: 80vh;">
        <div v-if="!isAccountUser">
          <div v-if="isSysAdmin" class="mb-4">
            <label for="accountSelect" class="block text-sm font-medium text-gray-700 mb-2">Seleccionar Cuenta</label>
            <select
              id="accountSelect"
              v-model="selectedAccountId"
              @change="fetchUsageData"
              class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                     focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
            >
              <option v-for="account in accounts" :key="account.id" :value="account.id">
                {{ account.name }}
              </option>
            </select>
          </div>

          <div class="flex justify-between items-center mb-6">
            <h4 v-if="usageData" class="text-xl font-bold text-gray-700">
              {{ $t('accountUsage.subTitle') }}
            </h4>
            <div class="flex items-center">
              <label for="yearSelect" class="block text-sm font-medium text-gray-700 mr-2">{{ $t('accountUsage.year') }}</label>
              <select
                id="yearSelect"
                v-model="selectedYear"
                @change="fetchUsageData"
                class="block w-32 px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                       focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              >
                <option v-for="year in availableYears" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="isLoading" class="text-center py-4">
            <div class="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
            <p class="mt-2 text-gray-600">{{ $t('accountUsage.loadingData') }}</p>
          </div>

          <div v-else-if="usageData">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div v-for="usage in usageData.usageData" :key="`${usage.year}-${usage.month}`" class="bg-white p-6 rounded-lg shadow-md">
                <h5 class="text-lg font-semibold mb-4 text-indigo-600">{{ getMonthName(usage.month) }} {{ usage.year }}</h5>
                
                <div class="space-y-6">
                  <div v-if="usage.limits.maxUsers > 0">
                    <div class="flex justify-between text-sm text-gray-600 mb-1 text-left">
                      <span>{{ $t('accountUsage.users') }}</span>
                      <span class="text-right text-xs ml-2">{{ usageData.userCount }} / {{ usage.limits.maxUsers }} ({{ calculatePercentage(usageData.userCount, usage.limits.maxUsers) }}%)</span>
                    </div>
                    <div class="w-full bg-gray-200 rounded-full h-4">
                      <div class="bg-purple-300 h-4 rounded-full" :style="{ width: `${Math.min(calculatePercentage(usageData.userCount, usage.limits.maxUsers), 100)}%` }"></div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="flex justify-between text-sm text-gray-600 mb-1 text-left">
                      <span>{{ $t('accountUsage.users') }}</span>
                      <span class="text-right text-xs ml-2">{{ usageData.userCount }} / {{ $t('accountUsage.noLimit') }}</span>
                    </div>
                    <div class="w-full bg-gray-200 rounded-full h-4">
                      <div class="bg-purple-300 h-4 rounded-full" :style="{ width: `25%` }"></div>
                    </div>                    
                  </div>

                  <div>
                    <div class="flex justify-between text-sm text-gray-600 mb-1 text-left">
                      <span>{{ $t('accountUsage.knowledgeBase') }}</span>
                      <span class="text-right text-xs ml-2">{{ formatBytes(usage.diskUsageInBytes) }} / {{ formatBytes(usage.limits.maxDiskUsageInBytes) }} ({{ calculatePercentage(usage.diskUsageInBytes, usage.limits.maxDiskUsageInBytes) }}%)</span>
                    </div>
                    <div class="w-full bg-gray-200 rounded-full h-4">
                      <div class="bg-blue-300 h-4 rounded-full" :style="{ width: `${Math.min(calculatePercentage(usage.diskUsageInBytes, usage.limits.maxDiskUsageInBytes), 100)}%` }"></div>
                    </div>
                  </div>

                  <div>
                    <div class="flex justify-between text-sm text-gray-600 mb-1 text-left">
                      <span>{{ $t('accountUsage.knowledgeTokens') }}</span>
                      <span class="text-right text-xs ml-2">{{ usage.embeddingTokensUsed.toLocaleString() }} / {{ usage.limits.maxEmbeddingTokensPerMonth.toLocaleString() }} ({{ calculatePercentage(usage.embeddingTokensUsed, usage.limits.maxEmbeddingTokensPerMonth) }}%)</span>
                    </div>
                    <div class="w-full bg-gray-200 rounded-full h-4">
                      <div class="bg-yellow-300 h-4 rounded-full" :style="{ width: `${Math.min(calculatePercentage(usage.embeddingTokensUsed, usage.limits.maxEmbeddingTokensPerMonth), 100)}%` }"></div>
                    </div>
                  </div>

                  <div>
                    <div class="flex justify-between text-sm text-gray-600 mb-1 text-left">
                      <span>{{ $t('accountUsage.conversationTokens') }}</span>
                      <span class="text-right text-xs ml-2">{{ usage.conversationTokensUsed.toLocaleString() }} / {{ usage.limits.maxTokensPerMonth.toLocaleString() }} ({{ calculatePercentage(usage.conversationTokensUsed, usage.limits.maxTokensPerMonth) }}%)</span>
                    </div>
                    <div class="w-full bg-gray-200 rounded-full h-4">
                      <div class="bg-red-300 h-4 rounded-full" :style="{ width: `${Math.min(calculatePercentage(usage.conversationTokensUsed, usage.limits.maxTokensPerMonth), 100)}%` }"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p v-else class="text-center text-gray-500 mt-4">{{ $t('accountUsage.noDataForPeriod') }}</p>
        </div>
        <p v-else class="text-center text-gray-500 mt-4">{{ $t('accountUsage.noPermission') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, computed, watch } from 'vue';
import { XIcon } from 'lucide-vue-next';
import { getAccountUsage, getAccountList } from '../services/api';
import { useHead } from '@vueuse/head'
import { getCurrentUserRoles } from '../utils/auth';
import { useNotification } from '@/plugins/notificationPlugin';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const pageTitle = computed(() => t('accountUsage.title'));

useHead({
  title: pageTitle.value,
});

const { showError } = useNotification();

const emit = defineEmits(['close']);

const accounts = ref([]);
const selectedAccountId = ref(null);
const selectedYear = ref(new Date().getFullYear());
const usageData = ref(null);
const isLoading = ref(false);

const availableYears = computed(() => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 5 }, (_, i) => currentYear - i);
});

const userRoles = computed(() => getCurrentUserRoles());
const isAccountUser = computed(() => userRoles.value.includes('AccountUser'));
const isAccountAdmin = computed(() => userRoles.value.includes('AccountAdmin'));
const isSysAdmin = computed(() => userRoles.value.includes('SysAdmin'));

const getCurrentUserAccountId = () => {
  const accountId = localStorage.getItem('accountId');
  return accountId;
};

const fetchUsageData = async () => {
  isLoading.value = true;
  const accountId = isAccountAdmin.value ? getCurrentUserAccountId() : selectedAccountId.value;

  if (!accountId) {
    showError('Error: Cuenta no seleccionada.');
    isLoading.value = false;
    return;
  }

  try {
    const response = await getAccountUsage(accountId, selectedYear.value);
    usageData.value = response.data;
  } catch (error) {
    if (error.status === 404) {
      usageData.value = null;
    } else {
      showError(error.detail || 'Error al cargar los datos de uso. Por favor, intente de nuevo.');
    }
  } finally {
    isLoading.value = false;
  }
};

const fetchAccounts = async () => {
  if (isSysAdmin.value) {
    try {
      const response = await getAccountList();
      accounts.value = response.data;
      if (accounts.value.length > 0) {
        selectedAccountId.value = accounts.value[0].id;
      }
    } catch (error) {
      showError(error.detail || 'Error al cargar la lista de cuentas. Por favor, intente de nuevo.');
    }
  } else if (isAccountAdmin.value) {
    selectedAccountId.value = getCurrentUserAccountId();
  }
};

const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString(locale.value, { month: 'long' });
};

const formatBytes = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const calculatePercentage = (value, max) => {
  return Math.round((value / max) * 100);
};

watch(selectedAccountId, (newValue) => {
  if (newValue) {
    fetchUsageData();
  }
});

onMounted(async () => {
  await fetchAccounts();
  if (selectedAccountId.value) {
    await fetchUsageData();
  } else {
  }
});
</script>