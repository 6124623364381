import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import LoginScreen from '../components/LoginScreen.vue'
import ChatInterface from '../components/ChatInterface.vue'
import PasswordReset from '../components/PasswordReset.vue'
import CustomerSignup from '../components/CustomerSignup.vue'
import SuccessPage from '../components/SuccessPage.vue'
import CancelPage from '../components/CancelPage.vue'
import PromoReseller from '@/components/PromoReseller.vue'
import PromoBusiness from '@/components/PromoBusiness.vue'
import { isAuthenticated } from '../utils/auth';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginScreen,
  },
  {
    path: "/chat",
    name: "Chat",
    component: ChatInterface,
    meta: { requiresAuth: true },
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/signup",
    name: "Signup",
    component: CustomerSignup,
  },
  {
    path: '/success',
    name: 'Success',
    component: SuccessPage
  },
  {
    path: '/cancel',
    name: 'Cancel',
    component: CancelPage
  },
  {
    path: "/resellers",
    name: "PromoReseller",
    component: PromoReseller,
  },
  {
    path: "/business",
    name: "PromoBusiness",
    component: PromoBusiness,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({ name: 'Login', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router