<template>
  <div
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50">
    <div class="w-full max-w-6xl bg-white rounded-lg shadow-xl overflow-hidden flex flex-col max-h-[90vh]">
      <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
        <div class="flex items-center space-x-2">
          <img src="@/assets/img/logo.svg" alt="Aignis" class="h-6 w-auto" />
          <h3 class="text-xl font-bold">{{ $t('usersManagement.title') }}</h3>
        </div>
        <button @click="emit('close')"
          class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
          <XIcon class="h-6 w-6" />
        </button>
      </div>

      <div class="p-6 bg-gray-50 overflow-y-auto" style="max-height: 70vh;">

        <div v-if="isSysAdmin" class="mb-4">
          <label for="accountSelect" class="block text-sm font-medium text-gray-700 mb-2">Seleccionar Cuenta</label>
          <select id="accountSelect" v-model="selectedAccountId" @change="fetchUsers"
            class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500">
            <option v-for="account in accounts" :key="account.id" :value="account.id">
              {{ account.name }}
            </option>
          </select>
        </div>

        <div class="mb-4 flex justify-between items-center">
          <p class="text-sm text-left text-gray-600 p-3 hidden md:block">
            {{ $t('usersManagement.explanation') }}
          </p>
          <button @click="showUserForm = true"
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md whitespace-nowrap">
            {{ $t('usersManagement.addUser') }}
          </button>
        </div>
        <table v-if="users.length > 0" class="min-w-full divide-y divide-gray-200">
          <thead class="bg-indigo-50">
            <tr>
              <th @click="sortUsers('username')"
                class="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer">
                {{ $t('usersManagement.userName') }}
                <span v-if="sortBy === 'username'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
              </th>
              <th @click="sortUsers('email')"
                class="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer">
                {{ $t('usersManagement.email') }}
                <span v-if="sortBy === 'email'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
              </th>
              <th @click="sortUsers('roles')"
                class="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer">
                {{ $t('usersManagement.roles') }}
                <span v-if="sortBy === 'roles'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
              </th>
              <th @click="sortUsers('disabled')"
                class="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer">
                {{ $t('usersManagement.access') }}
                <span v-if="sortBy === 'disabled'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
              </th>
              <th @click="sortUsers('lastLogin')"
                class="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer">
                {{ $t('usersManagement.lastAccess') }}
                <span v-if="sortBy === 'lastLogin'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
              </th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>

          <tbody class="bg-white divide-y divide-gray-200">
            <template v-for="user in sortedUsers" :key="user.id">
              <tr :class="{ 'bg-gray-100 text-gray-500': user.disabled }">
                <td class="px-6 py-4 whitespace-nowrap text-left">{{ user.username }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-left">{{ user.email }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-left">
                  {{ user.roles.map(role => role === 'AccountAdmin' ? $t('usersManagement.admin') : role === 'AccountUser' ? $t('usersManagement.user') :
                  role).join(', ') }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-left">
                  <span :class="user.disabled ? 'text-red-600' : 'text-green-600'">
                    {{ user.disabled ? $t('usersManagement.inactive') : $t('usersManagement.active') }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-left">
                  {{ user.lastLogin ? new Date(user.lastLogin).toLocaleString('es-ES', { day: '2-digit', month:
                  '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
                  : 'Nunca' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button @click="editUser(user)" class="text-indigo-600 hover:text-indigo-900 mr-2">
                    <Edit2Icon class="h-5 w-5" />
                  </button>
                  <button @click="confirmDeleteUser(user.id)" class="text-red-600 hover:text-red-900">
                    <TrashIcon class="h-5 w-5" />
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <p v-else class="text-center text-gray-500 mt-4">{{ $t('usersManagement.noUsers') }}</p>
      </div>
    </div>

    <div v-if="showUserForm"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4">
      <div class="w-full max-w-md bg-white rounded-lg shadow-xl overflow-hidden">
        <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
          <h3 class="text-xl font-bold">{{ editingUser ? $t('usersManagement.edit') : $t('usersManagement.add') }} {{ $t('usersManagement.user') }}</h3>
          <button @click="closeUserForm"
            class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
            <XIcon class="h-6 w-6" />
          </button>
        </div>
        <div class="p-6 bg-gray-50">
          <form @submit.prevent="submitUserForm" class="space-y-4">
            <div class="relative">
              <label for="username" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('usersManagement.userName') }}
              </label>
              <input type="text" id="username" v-model="userForm.username"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('usersManagement.userNamePlaceholder')" required />
            </div>
            <div class="relative">
              <label for="email" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('usersManagement.email') }}
              </label>
              <input type="email" id="email" v-model="userForm.email"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('usersManagement.emailPlaceholder')" required />
            </div>
            <div class="relative">
              <label for="password" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('usersManagement.password') }}
              </label>
              <input type="password" id="password" v-model="userForm.password"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="editingUser ? $t('usersManagement.passwordPlaceholderLeaveBlank') : $t('usersManagement.passwordPlaceholderRequirements')"
                :required="!editingUser" />
            </div>
            <div v-if="isSysAdmin" class="relative">
              <label for="accountId" class="absolute left-3 top-2 px-1 bg-gray-200 text-xs font-medium text-gray-500">
                Cuenta
              </label>
              <p class="mt-1 block w-full px-3 pt-6 pb-2 bg-gray-200 text-gray-700 rounded-md text-sm text-left">
                {{ accounts.find(account => account.id === selectedAccountId)?.name || 'Cuenta no seleccionada' }}
              </p>
            </div>
            <div>
              <label class="block text-left text-sm font-medium text-gray-700 mb-2">{{ $t('usersManagement.roles') }}</label>
              <div class="flex flex-wrap gap-4">
                <label v-for="role in availableRoles" :key="role.id" class="inline-flex items-center">
                  <input type="radio" v-model="userForm.roles" :value="role.roleName"
                    class="form-radio h-5 w-5 text-indigo-600" />
                  <span class="ml-2 text-sm text-gray-700">
                    {{ role.roleName === 'AccountAdmin' ? $t('usersManagement.admin') : role.roleName === 'AccountUser' ? $t('usersManagement.user') :
                    role.roleName }}
                  </span>
                </label>
              </div>
            </div>
            <div>
              <label class="block text-left text-sm font-medium text-gray-700 mb-2">{{ $t('usersManagement.access') }}</label>
              <div class="flex flex-wrap gap-4">
                <label class="inline-flex items-center text-left">
                  <input type="checkbox" v-model="userForm.disabled" class="form-checkbox h-5 w-5 text-indigo-600" />
                  <span class="ml-2 text-sm text-gray-700">{{ $t('usersManagement.noAllowAccess') }}</span>
                </label>
              </div>
            </div>
            <div class="flex items-center justify-center">
              <button type="submit"
                class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out">
                {{ editingUser ? $t('usersManagement.edit') : $t('usersManagement.add') }} {{ $t('usersManagement.user') }}
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Confirmation modal -->
  <div v-if="showDeleteConfirm" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4 z-50">
    <div class="bg-white rounded-lg p-6 shadow-xl">
      <p class="text-lg text-gray-800 mb-4">{{ $t('usersManagement.deleteConfirmation') }}</p>
      <div class="flex justify-end space-x-4">
        <button @click="showDeleteConfirm = false"
          class="px-4 py-2 bg-gray-300 text-gray-800 rounded-md">{{ $t('usersManagement.cancel') }}</button>
        <button @click="deleteUser" class="px-4 py-2 bg-red-600 text-white rounded-md">{{ $t('usersManagement.delete') }}</button>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, computed, defineEmits } from 'vue';
import { XIcon, Edit2Icon, TrashIcon } from 'lucide-vue-next';
import { getUserList, createUser, updateUserById, deleteUser as apiDeleteUser, getUserRoles, getAccountList } from '../services/api';
import { getCurrentUserRoles } from '../utils/auth';
import { useHead } from '@vueuse/head'
import { useNotification } from '@/plugins/notificationPlugin';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const pageTitle = computed(() => t('usersManagement.title'));

useHead({
  title: pageTitle.value,
});

const { showSuccess, showError } = useNotification();

const emit = defineEmits(['close']);

const isSysAdmin = computed(() => {
  const roles = getCurrentUserRoles();
  return roles.includes('SysAdmin');
});
const isAccountAdmin = computed(() => {
  const roles = getCurrentUserRoles();
  return roles.includes('AccountAdmin');
});

const getCurrentUserAccountId = () => {
  const accountId = localStorage.getItem('accountId');
  return accountId;
};

const users = ref([]);
const showUserForm = ref(false);
const editingUser = ref(null);
const availableRoles = ref([]);
const accounts = ref([]);
const userForm = ref({
  username: '',
  email: '',
  password: '',
  roles: [],
  disabled: null,
  accountId: null
});

const selectedAccountId = ref(null);

const sortedUsers = computed(() => {
  return [...users.value].sort((a, b) => {
    if (a.disabled === b.disabled) return 0;
    return a.disabled ? 1 : -1;
  });
});

const fetchUsers = async () => {
  try {
    const accountId = selectedAccountId.value;
    if (!accountId) {
      showError('Error: Cuenta no seleccionada.');
      return;
    }
    const response = await getUserList(accountId);
    users.value = response.data;
  } catch (error) {
      showError(error.detail || 'Error al cargar los usuarios.');
  }
};

const fetchRoles = async () => {
  try {
    const response = await getUserRoles();
    availableRoles.value = response.data;
  } catch (error) {
    showError(error.detail || 'Error al cargar los roles disponibles. Por favor, intente de nuevo.');
  }
};

const fetchAccounts = async () => {
  if (isSysAdmin.value) {
    try {
      const response = await getAccountList(); 
      accounts.value = response.data; 
      if (accounts.value.length > 0) {
        selectedAccountId.value = accounts.value[0].id; // Asignar la primera cuenta por defecto
        await fetchUsers();
      }
    } catch (error) {
      showError(error.detail || 'Error al cargar las cuentas disponibles. Por favor, intente de nuevo.');
    }
  }
};

const editUser = (user) => {
  editingUser.value = user;
  userForm.value = { 
    ...user, 
    password: '',
    roles: user.roles.length > 0 ? (user.roles[0].roleName || user.roles[0]) : '', // Solo selecciona el primer rol
    disabled: user.disabled !== null,
    accountId: user.accountId
  };
  showUserForm.value = true;
};

const showDeleteConfirm = ref(false);
const userToDelete = ref(null);

const confirmDeleteUser = (userId) => {
  userToDelete.value = userId;
  showDeleteConfirm.value = true;
};

const deleteUser = async () => {
  const accountId = selectedAccountId.value;
  try {
    const response = await apiDeleteUser(accountId, userToDelete.value);
    showSuccess(response.detail || 'Acción realizada con éxito.');
    await fetchUsers();
  } catch (error) {
    console.log(error);
    showError(error.detail || 'Error al eliminar el usuario. Por favor, intente de nuevo.');
  } finally {
    showDeleteConfirm.value = false;
    userToDelete.value = null;
  }
};

const submitUserForm = async () => {
  try {
    // Usa el selectedAccountId para crear el usuario con la cuenta seleccionada
    const accountId = selectedAccountId.value;
    if (!accountId) {
      showError('Error: No se ha seleccionado una cuenta.');
      return;
    }

    const formData = {
      ...userForm.value,
      accountId, 
      disabled: userForm.value.disabled ? new Date().toISOString() : null
    };

    if (userForm.value.roles.length > 0) {
      formData.roles = [userForm.value.roles]; // Convertir el rol en un array con un solo elemento
    }

    let response;
    if (editingUser.value) {
      response = await updateUserById(accountId, editingUser.value.id, formData);
    } else {
      response = await createUser(formData);
    }
    await fetchUsers(); 
    showSuccess(response.detail || 'Acción realizada con éxito.');
    closeUserForm();
  } catch (error) {
    showError(error.detail || 'Error al guardar el usuario. Por favor, verifique los datos e intente de nuevo.');
  }
};

const closeUserForm = () => {
  showUserForm.value = false;
  editingUser.value = null;
  userForm.value = { username: '', email: '', password: '', roles: [], disabled: null, accountId: null };
};

onMounted(async () => {
  await fetchRoles();
  if (isSysAdmin.value) {
    await fetchAccounts(); // Si es sysadmin, mostrar el selector de cuentas
    if (selectedAccountId.value) {
      await fetchUsers();
    }
  } else if (isAccountAdmin.value) {
      // Si es accountadmin, cargar su accountId automáticamente
      selectedAccountId.value = getCurrentUserAccountId();
      await fetchUsers(); // Cargar usuarios de su propia cuenta
  } else {
      await fetchUsers(); // En caso de otros roles
  }
});

const sortBy = ref('username'); // default order
const sortDirection = ref('asc'); // default order

const sortUsers = (column) => {
if (sortBy.value === column) {
  sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
} else {
  sortBy.value = column;
  sortDirection.value = 'asc';
}

users.value.sort((a, b) => {
  const valA = a[column] || ''; // Para manejar valores nulos
  const valB = b[column] || '';

  if (valA === valB) return 0;

  if (sortDirection.value === 'asc') {
    return valA > valB ? 1 : -1;
  } else {
    return valA < valB ? 1 : -1;
  }
});
};
</script>