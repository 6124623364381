<template>
  <div v-if="isSysAdmin"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50">
    <div class="w-full max-w-7xl bg-white rounded-lg shadow-xl overflow-hidden flex flex-col">
      <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
        <div class="flex items-center space-x-2">
          <img src="@/assets/img/logo.svg" alt="Aignis" class="h-6 w-auto" />
          <h3 class="text-xl font-bold">Proveedores de IA</h3>
        </div>
        <button @click="$emit('close')"
          class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
          <XIcon class="h-6 w-6" />
        </button>
      </div>
      <div class="p-6 bg-gray-50 overflow-y-auto" style="max-height: 70vh;">
        <!-- Botón para crear proveedor IA -->
        <div class="mb-4 flex justify-end">
          <button @click="showProviderForm = true"
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md">
            Crear Proveedor IA
          </button>
        </div>

        <!-- Lista de proveedores IA -->
        <div class="mb-8">
          <h4 class="text-lg font-semibold mb-4">Lista de Proveedores IA</h4>
          <div class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-indigo-50">
                <tr>
                  <th v-for="header in tableHeaders" :key="header" scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ header }}
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="provider in aiProviders" :key="provider.id">
                  <td v-for="field in tableFields" :key="field" class="px-6 py-4 whitespace-nowrap text-left">
                    {{ provider[field] }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button @click="editProvider(provider)" class="text-indigo-600 hover:text-indigo-900 mr-2">
                      <Edit2Icon class="h-5 w-5" />
                    </button>
                    <button @click="deleteProvider(provider.id)" class="text-red-600 hover:text-red-900">
                      <TrashIcon class="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Formulario para crear/editar proveedor IA -->
    <div v-if="showProviderForm"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4">
      <div class="w-full max-w-md bg-white rounded-lg shadow-xl overflow-hidden">
        <div class="bg-indigo-600 p-4 text-white flex justify-between items-center">
          <h3 class="text-xl font-bold">{{ editingProvider ? 'Editar' : 'Crear' }} Proveedor IA</h3>
          <button @click="closeProviderForm"
            class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
            <XIcon class="h-6 w-6" />
          </button>
        </div>
        <div class="p-6 bg-gray-50">
          <form @submit.prevent="submitProviderForm" class="space-y-4">
            <div v-for="field in formFields" :key="field.id" class="relative">
              <label :for="field.id" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ field.label }}
              </label>
              <input :type="field.type" :id="field.id" v-model="providerForm[field.id]"
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="field.placeholder" :required="field.required" :step="field.step" :min="field.min" :max="field.max" />
            </div>
            <div class="flex items-center justify-center pt-4">
              <button type="submit"
                class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out">
                {{ editingProvider ? 'Actualizar' : 'Crear' }} Proveedor IA
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { getCurrentUserRoles } from '../utils/auth';
import { XIcon, Edit2Icon, TrashIcon } from 'lucide-vue-next';
import { getAIProviderList, createAIProvider, updateAIProvider, deleteAIProvider } from '../services/api';
import { useHead } from '@vueuse/head'
import { useNotification } from '@/plugins/notificationPlugin';

const isSysAdmin = computed(() => {
  const roles = getCurrentUserRoles();
  return roles.includes('SysAdmin');
});

const { showSuccess, showError } = useNotification();

useHead({
  title: 'Gestión de Proveedores IA - Aignis',
  meta: [
    {
      name: 'description',
      content: 'Administra los proveedores de IA en Aignis.'
    }
  ]
});

const aiProviders = ref([]);
const showProviderForm = ref(false);
const editingProvider = ref(false);
const providerForm = ref({
  id: '',
  name: '',
  apiKey: '',
  chatModel: '',
  modelPromptMaxTokens: 0,
  embeddingModel: '',
  temperature: 0,
  maxTokens: 0,
  topP: 0,
  presencePenalty: 0
});

const tableHeaders = [
  'ID', 'Nombre', 'Modelo de Chat', 'Max Tokens del Modelo', 'Modelo de Embedding',
  'Temperatura', 'Max Tokens de respuesta', 'Top P', 'Penalización de Presencia'
];

const tableFields = [
  'id', 'name', 'chatModel', 'modelPromptMaxTokens', 'embeddingModel',
  'temperature', 'maxTokens', 'topP', 'presencePenalty'
];

const formFields = [
  { id: 'name', label: 'Nombre', type: 'text', placeholder: 'Nombre identificativo', required: true },
  { id: 'apiKey', label: 'API Key', type: 'text', placeholder: 'Dejar en blanco para mantener la actual' },
  { id: 'chatModel', label: 'Modelo de Chat', type: 'text', placeholder: 'Modelo de Chat', required: true },
  { id: 'modelPromptMaxTokens', label: 'Máximo de Tokens del Modelo', type: 'number', placeholder: 'Máximo de Tokens del Prompt', required: true },
  { id: 'embeddingModel', label: 'Modelo de Embedding', type: 'text', placeholder: 'Modelo de Embedding', required: true },
  { id: 'temperature', label: 'Temperatura', type: 'number', placeholder: 'Temperatura', required: true, step: 0.1, min: 0, max: 1 },
  { id: 'maxTokens', label: 'Máximo de Tokens de respuesta', type: 'number', placeholder: 'Máximo de Tokens', required: true },
  { id: 'topP', label: 'Top P', type: 'number', placeholder: 'Top P', required: true, step: 0.1, min: 0, max: 1 },
  { id: 'presencePenalty', label: 'Penalización de Presencia', type: 'number', placeholder: 'Penalización de Presencia', required: true, step: 0.1, min: 0, max: 1 }
];

const fetchAIProviders = async () => {
  try {
    const providers = await getAIProviderList();
    aiProviders.value = providers.data;
  } catch (error) {
    showError(error.detail || 'Error al cargar los proveedores IA. Por favor, intente de nuevo.');
  }
};

const submitProviderForm = async () => {
  try {
    if (editingProvider.value) {
      const response = await updateAIProvider(providerForm.value.id, providerForm.value);
      showSuccess(response.detail || 'Acción realizada con éxito.');
    } else {
      const response = await createAIProvider(providerForm.value);
      showSuccess(response.detail || 'Acción realizada con éxito.');
    }
    closeProviderForm();
    await fetchAIProviders();
  } catch (error) {
    console.log(error); 
    showError(error.detail || `Error al ${editingProvider.value ? 'actualizar' : 'crear'} el proveedor IA. Por favor, intente de nuevo.`);
  }
};

const editProvider = (provider) => {
  providerForm.value = { ...provider };
  editingProvider.value = true;
  showProviderForm.value = true;
};

const deleteProvider = async (providerId) => {
  if (confirm('¿Estás seguro de que quieres eliminar este proveedor IA?')) {
    try {
      await deleteAIProvider(providerId);
      showSuccess(response.detail || 'Acción realizada con éxito.');
      await fetchAIProviders();
    } catch (error) {
      showError(error.detail || 'Error al eliminar el proveedor IA. Por favor, intente de nuevo.');
    }
  }
};

const closeProviderForm = () => {
  showProviderForm.value = false;
  editingProvider.value = false;
  providerForm.value = {
    id: '',
    name: '',
    apiKey: '',
    chatModel: '',
    modelPromptMaxTokens: 0,
    embeddingModel: '',
    temperature: 0,
    maxTokens: 0,
    topP: 0,
    presencePenalty: 0
  };
};

onMounted(async () => {
  await fetchAIProviders();
});
</script>