import { ref, readonly } from 'vue'

const notification = ref({ message: '', type: 'info', show: false })

const showNotification = (message, type = 'info') => {
  notification.value = { message, type, show: true }
  setTimeout(() => {
    hideNotification()
  }, 5000)
}

const hideNotification = () => {
  notification.value.show = false
}

const showError = (message) => {
  showNotification(message, 'error')
}

const showSuccess = (message) => {
  showNotification(message, 'success')
}

export default {
  install: (app) => {
    app.provide('notification', {
      notification: readonly(notification),
      showNotification,
      hideNotification,
      showError,
      showSuccess
    })
  }
}

export const useNotification = () => {
  return {
    notification: readonly(notification),
    showNotification,
    hideNotification,
    showError,
    showSuccess
  }
}