export const setUserInfo = (userInfo) => {
  localStorage.setItem('userId', userInfo.userId);
  localStorage.setItem('accountId', userInfo.accountId);
  localStorage.setItem('username', userInfo.username);
  localStorage.setItem('roles', JSON.stringify(userInfo.roles));
  localStorage.setItem('expiration', userInfo.expiration);
  localStorage.setItem('authToken', userInfo.token);
};

export const clearUserInfo = () => {
  localStorage.removeItem('userId');
  localStorage.removeItem('accountId');
  localStorage.removeItem('username');
  localStorage.removeItem('roles');
  localStorage.removeItem('expiration');
  localStorage.removeItem('authToken');
};

export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const getCurrentUserRoles = () => {
  const roles = localStorage.getItem('roles');
  return roles ? JSON.parse(roles) : [];
};

export const hasRole = (role) => {
  const roles = getCurrentUserRoles();
  return roles.includes(role);
};

export const isAuthenticated = () => {
  const token = getAuthToken();
  const expiration = localStorage.getItem('expiration');

  if (!token || !expiration) {
    return false;
  }

  const now = new Date().getTime();
  const expirationTime = new Date(expiration).getTime();
  return now < expirationTime;
};