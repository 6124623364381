<template>
  <div id="knowledge-base-modal"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50">
    <div class="w-full max-w-6xl bg-white rounded-lg shadow-xl overflow-hidden flex flex-col max-h-[90vh]">
      <!-- Header -->
      <div class="bg-indigo-600 p-4 text-white flex justify-between items-center sticky top-0 z-10">
        <div class="flex items-center space-x-2">
          <img src="@/assets/img/logo.svg" alt="Aignis" class="h-6 w-auto" />
          <h3 class="text-xl font-bold">{{ $t('knowledgeBase.title') }}</h3>
        </div>
        <button @click="closeModal"
          class="text-indigo-200 hover:text-white focus:outline-none transition duration-150 ease-in-out">
          <XIcon class="h-6 w-6" />
        </button>
      </div>

      <div ref="scrollableArea" class="relative p-6 bg-gray-50 overflow-y-auto flex-grow">
        <!-- Loader -->
        <div v-if="isUploading || isLoading"
          class="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-75 z-30">
          <Loader class="h-12 w-12 animate-spin text-indigo-600" />
          <p class="text-sm text-gray-600 mt-4 text-center">
            {{ isUploading ? $t('knowledgeBase.uploading') : $t('knowledgeBase.uploadingProcess') }}
          </p>
        </div>

        <!-- Instructions and buttons -->
        <div class="mb-4 flex flex-col items-center text-left gap-4">
          <p class="text-sm text-gray-600 p-3 hidden md:block">
            {{ $t('knowledgeBase.explanation') }}
          </p>
          <div class="flex justify-center flex-wrap gap-2">
            <button @click="toggleDbConnectionForm"
              class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-150 ease-in-out whitespace-nowrap">
              <DatabaseIcon class="inline h-5 w-5 mr-2" />
              {{ $t('knowledgeBase.addDbConnection') }}
            </button>
            <button @click="toggleUploadArea"
              class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md transition duration-150 ease-in-out whitespace-nowrap">
              <FileUp class="inline h-5 w-5 mr-2" />
              {{ $t('knowledgeBase.addDocuments') }}
            </button>
          </div>
        </div>

        <!-- DB Connection Form -->
        <div v-if="showDbConnectionForm" class="mb-6 bg-white p-4 rounded-lg shadow max-w-3xl mx-auto">
          <h4 class="text-lg font-semibold mb-4">
            {{ editingConnection ? $t('knowledgeBase.editDbConnection') : $t('knowledgeBase.addDbConnection') }}
          </h4>
          <form @submit.prevent="submitDbConnection" class="space-y-4">
            <div class="relative">
              <label for="connectionName" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('knowledgeBase.connectionName') }}
              </label>
              <input v-model="dbConnection.name" id="connectionName" type="text" required
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('knowledgeBase.connectionNamePlaceholder')">
            </div>
            <div class="relative">
              <label for="connectionString"
                class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('knowledgeBase.connectionString') }}
              </label>
              <input v-model="dbConnection.connectionString" id="connectionString" type="text" required
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                :placeholder="$t('knowledgeBase.connectionStringPlaceholder')">
            </div>
            <div class="relative">
              <label for="provider" class="absolute left-3 top-2 px-1 bg-white text-xs font-medium text-gray-500">
                {{ $t('knowledgeBase.provider') }}
              </label>
              <select v-model="dbConnection.provider" id="provider" required
                class="mt-1 block w-full px-3 pt-6 pb-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 appearance-none">
                <option v-for="(value, key) in DatabaseProvider" :key="key" :value="value">
                  {{ $t(`knowledgeBase.databaseProviders.${key}`) }}
                </option>
              </select>
            </div>
            <div class="flex justify-center space-x-2">
              <button type="button" @click="toggleDbConnectionForm"
                class="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                {{ $t('knowledgeBase.close') }}
              </button>
              <button type="submit"
                class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out">
                {{ editingConnection ? $t('knowledgeBase.editDbConnection') : $t('knowledgeBase.addDbConnection') }}
              </button>
            </div>
          </form>
          <div class="text-xs mt-6">
            {{ $t('knowledgeBase.databaseExplanation') }}
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm mt-6">
            <h3 className="text-sm font-semibold text-gray-700 mb-2">{{ $t('knowledgeBase.connectionExamples') }}</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full text-xs">
                <tbody>
                  <tr>
                    <td className="pr-4 py-2 font-medium text-gray-600">Microsoft SQL Server:</td>
                    <td className="py-2 font-mono text-gray-800">Server=myserver;Database=mydb;User
                      Id=myuser;Password=mypassword;</td>
                  </tr>
                  <tr>
                    <td className="pr-4 py-2 font-medium text-gray-600">MySQL / MariaDB:</td>
                    <td className="py-2 font-mono text-gray-800">
                      Server=myserver;Database=mydb;Uid=myuser;Pwd=mypassword;</td>
                  </tr>
                  <tr>
                    <td className="pr-4 py-2 font-medium text-gray-600">PostgreSQL:</td>
                    <td className="py-2 font-mono text-gray-800">
                      Host=myserver;Database=mydb;Username=myuser;Password=mypassword;</td>
                  </tr>
                  <tr>
                    <td className="pr-4 py-2 font-medium text-gray-600">SQLite:</td>
                    <td className="py-2 font-mono text-gray-800">
                      Data Source=mydb.db; <span className="text-gray-600 italic">{{
                        $t('knowledgeBase.localInstallations') }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <!-- Área de subida de archivos (desplegable) -->
        <div v-if="showUploadArea" ref="uploadArea"
          class="upload-area-wrapper mb-6 bg-white p-4 rounded-lg shadow max-w-3xl mx-auto"
          :style="{ height: uploadAreaHeight }">
          <h4 class="text-lg font-semibold mb-4">
            {{ $t('knowledgeBase.addDocuments') }}
          </h4>
          <div
            class="uploadAreaInput border-2 border-dashed border-indigo-300 rounded-lg text-center cursor-pointer hover:bg-indigo-50 transition-colors"
            @dragover.prevent="dragging = true" @dragleave="dragging = false" @drop.prevent="handleFileDrop"
            @click="openFileSelector" :class="{'bg-indigo-50': dragging || selectedFiles.length > 0}">
            <p class="text-gray-500" style="margin-top: 60px;" v-if="!selectedFiles.length">
              {{ $t('knowledgeBase.dragFilesOrClick') }}
            </p>
            <ul v-if="selectedFiles.length > 0" class="text-left mt-4 text-sm max-h-24 p-2 overflow-y-auto">
              <li v-for="(file, index) in selectedFiles" :key="index" class="truncate">
                &bull; {{ file.name }} ({{ formatFileSize(file.size) }})
              </li>
            </ul>
            <input type="file" ref="fileInput" @change="handleFileUpload" multiple class="hidden" />
          </div>

          <div class="flex justify-center space-x-2 mt-4">
            <button type="button" @click="toggleUploadArea"
              class="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {{ $t('knowledgeBase.close') }}
            </button>
            <button @click="uploadFiles"
              class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out"
              :disabled="isUploading || !selectedFiles.length">
              {{ isUploading ? $t('knowledgeBase.uploading') : $t('knowledgeBase.upload') }}
            </button>
          </div>
          <div class="text-xs mt-6">
            {{ $t('knowledgeBase.uploadExplanation') }}
          </div>
        </div>

        <!-- DB Connections Table -->
        <div v-if="dbConnections.length > 0" class="mt-8">
          <h4 class="text-lg font-semibold mb-4 text-left">{{ $t('knowledgeBase.dbConnections') }}</h4>
          <div class="overflow-x-auto bg-white shadow-md rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-indigo-50">
                <tr>
                  <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('knowledgeBase.name') }}
                  </th>
                  <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {{ $t('knowledgeBase.provider') }}
                  </th>
                  <th class="px-4 py-3 text-right"></th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="connection in dbConnections" :key="connection.id" class="hover:bg-gray-100">
                  <td class="px-4 py-3 whitespace-nowrap text-left">
                    <div class="text-sm font-medium text-gray-900">
                      <DatabaseIcon class="text-indigo-600 mr-2 inline h-5 w-5" />
                      {{ connection.name }}
                    </div>
                  </td>
                  <td class="px-4 py-3 whitespace-nowrap text-left">
                    <div class="text-sm text-gray-500">
                      {{ $t(`knowledgeBase.databaseProviders.${getProviderName(connection.provider)}`) }}
                    </div>
                  </td>
                  <td class="px-4 py-3 whitespace-nowrap text-right text-sm font-medium text-right">
                    <button @click="editDbConnection(connection)" class="text-indigo-600 hover:text-indigo-900 mr-2">
                      <Edit2Icon class="h-5 w-5" />
                    </button>
                    <button @click="deleteDbConnection(connection.id)" class="text-red-600 hover:text-red-900">
                      <TrashIcon class="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Archivos existentes -->
        <div v-if="files.length > 0" class="mt-8">
          <h4 class="text-lg font-semibold mb-4 text-left">{{ $t('knowledgeBase.existingFiles') }}</h4>
          <div class=" overflow-x-auto bg-white shadow-md rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-indigo-50">
                <tr>
                  <th @click="sortFiles('fileName')"
                    class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                    {{ $t('knowledgeBase.name') }}
                    <span v-if="sortBy === 'fileName'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
                  </th>
                  <th @click="sortFiles('size')"
                    class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                    {{ $t('knowledgeBase.size') }}
                    <span v-if="sortBy === 'size'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
                  </th>
                  <th @click="sortFiles('created')"
                    class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                    {{ $t('knowledgeBase.creationDate') }}
                    <span v-if="sortBy === 'created'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
                  </th>
                  <th class="px-4 py-3 text-right"></th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="file in files" :key="file.fileName" class="hover:bg-gray-100">
                  <td class="px-4 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <span class="text-indigo-600 mr-2">
                        <component :is="getFileIcon(file.fileName)" class="h-5 w-5" />
                      </span>
                      <span class="text-sm font-medium text-gray-900 truncate max-w-xl" :title="file.fileName">
                        {{ file.fileName }}
                      </span>
                    </div>
                  </td>
                  <td class="px-4 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                    {{ formatFileSize(file.size) }}
                  </td>
                  <td class="px-4 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                    {{ new Date(file.created).toLocaleString('es-ES', { day: '2-digit', month:
                    '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
                    }}
                  </td>
                  <td class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button @click="deleteFile(file.fileName)"
                      class="text-red-600 hover:text-red-900 transition duration-150 ease-in-out"
                      :disabled="isUploading">
                      <TrashIcon class="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, computed } from 'vue';
import { Loader, XIcon, BookType, FileIcon, FileTextIcon, FileType, FileCode, TrashIcon, FileUp, DatabaseIcon, Edit2Icon } from 'lucide-vue-next';
import { uploadFile, listFiles, deleteFile as apiDeleteFile, createDatabaseConnection, listDatabaseConnections, updateDatabaseConnection, deleteDatabaseConnection } from '../services/api';
import { useHead } from '@vueuse/head'
import { useNotification } from '@/plugins/notificationPlugin';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const pageTitle = computed(() => t('knowledgeBase.title'));

useHead({
  title: pageTitle.value,
});

const { showSuccess, showError } = useNotification();

const emit = defineEmits(['close']);
const closeModal = () => {
  emit('close');
};

const files = ref([]);
const selectedFiles = ref([]);
const isUploading = ref(false);
const dragging = ref(false);
const fileInput = ref(null);
const scrollableArea = ref(null);
const uploadArea = ref(null);
const showUploadArea = ref(false);
const uploadAreaHeight = ref('0px');

const toggleUploadArea = async () => {
  showUploadArea.value = !showUploadArea.value;
  await nextTick();
  scrollableArea.value.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  if (showUploadArea.value && uploadArea.value) {
    uploadAreaHeight.value = `${uploadArea.value.scrollHeight}px`;
  } else {
    uploadAreaHeight.value = '0px';
  }
};

const openFileSelector = () => {
  fileInput.value.click();
};

const handleFileDrop = (event) => {
  dragging.value = false;
  selectedFiles.value = [...event.dataTransfer.files];
};

const handleFileUpload = (event) => {
  selectedFiles.value = [...event.target.files];
};

const uploadFiles = async () => {
  if (selectedFiles.value.length === 0) return;

  isUploading.value = true;
  let allUploadsSuccessful = true;

  for (let file of selectedFiles.value) {
    try {
      await uploadFile(file);
      showSuccess(`${t('knowledgeBase.file')} ${file.name} ${t('knowledgeBase.uploadedOk')}`);
    } catch (error) {
      allUploadsSuccessful = false;
      showError(`${t('knowledgeBase.uploadedError')} ${file.name}`);
    }
  }

  await fetchFiles(); 
  selectedFiles.value = []; 

  if (allUploadsSuccessful) {
    showSuccess(t('knowledgeBase.allOk'));
  } else {
    showError(t('knowledgeBase.someKo'));
  }

  isUploading.value = false;
};

const fetchFiles = async () => {
  try {
    const response = await listFiles();
    files.value = response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      files.value = [];
    } else {
      showError(error.detail || t('knowledgeBase.fetchFilesError'));
    }
  }
};

const deleteFile = async (fileName) => {
  if (isUploading.value) return;

  try {
    await apiDeleteFile(fileName);
    showSuccess(t('knowledgeBase.deletedOk'));
    await fetchFiles();
  } catch (error) {
    showError(error.detail || t('knowledgeBase.deleteError'));
  }
};

const getFileIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return BookType;
    case 'doc':
    case 'docx':
      return FileType;
    case 'txt':
    case 'md':
      return FileTextIcon;    
    case 'csv':
    case 'json':
    case 'xml':
      return FileCode;    
    default:
      return FileIcon;
  }
};

const dbConnections = ref([]);
const showDbConnectionForm = ref(false);
const editingConnection = ref(null);
const isLoading = ref(false);

const dbConnection = ref({
  name: '',
  connectionString: '',
  provider: 0, // Default to the first provider
});

const DatabaseProvider = {
  SqlServer: 0,
  MySql: 1,
  PostgreSql: 2,
  SQLite: 3,
};

const getProviderName = (providerId) => {
  return Object.keys(DatabaseProvider).find(key => DatabaseProvider[key] === providerId) || 'Unknown';
};

const toggleDbConnectionForm = async () => {
  showDbConnectionForm.value = !showDbConnectionForm.value; 
  if (!showDbConnectionForm.value) {
    editingConnection.value = null;
    dbConnection.value = { name: '', connectionString: '', provider: 0 };
  }
};

const submitDbConnection = async () => {
  try {
    isLoading.value = true;
    if (editingConnection.value) {
      const result = await updateDatabaseConnection(editingConnection.value.id, dbConnection.value);
      showSuccess(result.detail);
    } else {
      const result = await createDatabaseConnection(dbConnection.value);
      showSuccess(result.detail);
    }
    await fetchDbConnections();
    toggleDbConnectionForm();
  } catch (error) {
    showError(error.detail);
  } finally {
    isLoading.value = false;
  }
};

const cancelDbConnectionForm = () => {
  toggleDbConnectionForm();
};

const editDbConnection = (connection) => {
  editingConnection.value = connection;
  dbConnection.value = { ...connection };
  showDbConnectionForm.value = true;
};

const deleteDbConnection = async (id) => {
  if (confirm(t('knowledgeBase.confirmDeleteConnection'))) {
    try {
      isLoading.value = true;
      const result = await deleteDatabaseConnection(id);
      showSuccess(result.detail);
      await fetchDbConnections();
    } catch (error) {
      showError(error.detail);
    } finally {
      isLoading.value = false;
    }
  }
};

const fetchDbConnections = async () => {
  try {
    isLoading.value = true;
    const response = await listDatabaseConnections();
    dbConnections.value = response.data;
  } catch (error) {
    showError(error.message || t('knowledgeBase.fetchConnectionsError'));
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchFiles();
  fetchDbConnections();
});

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const sortBy = ref('fileName'); // default sort
const sortDirection = ref('asc'); // default sort
const sortFiles = (column) => {
  if (sortBy.value === column) {
    // Si ya estamos ordenando por esta columna, invertimos la dirección
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    // Si es una nueva columna, ordenamos de forma ascendente por defecto
    sortBy.value = column;
    sortDirection.value = 'asc';
  }

  // Ordenamos los archivos ignorando mayúsculas y minúsculas
  files.value.sort((a, b) => {
    const valA = a[column].toLowerCase();
    const valB = b[column].toLowerCase();

    if (valA === valB) return 0;

    if (sortDirection.value === 'asc') {
      return valA > valB ? 1 : -1;
    } else {
      return valA < valB ? 1 : -1;
    }
  });
};
</script>
