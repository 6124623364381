<template>
    <div class="w-full max-w-6xl mx-auto bg-gradient-to-b from-white to-gray-50 p-8 lg:p-12 shadow-xl rounded-2xl">
        <!-- Header -->
        <div class="flex flex-col items-center text-center mb-12">
            <img src="@/assets/img/logo-inverted.svg"
                alt="Aignis Logo" class="w-20 h-20 mb-4" />
            <h1
                class="text-4xl py-1 font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#4F46E5] to-[#7C3AED] mb-4">
                Aignis
            </h1>
            <p class="text-xl font-medium text-gray-700 max-w-2xl">
                Haz llegar tu conocimiento a más personas con una IA personalizada
            </p>
        </div>

        <!-- Main Feature -->
        <div
            class="mb-12 overflow-hidden border-0 bg-gradient-to-br from-[#4F46E5]/10 to-transparent rounded-lg shadow-md">
            <div class="p-8">
                <div class="flex flex-col md:flex-row items-center gap-8">
                    <div class="flex-1">
                        <h2 class="text-3xl font-bold mb-4 text-gray-800">Tu Conocimiento, Tu IA</h2>
                        <p class="text-lg text-gray-600 mb-6">
                            Aignis te permite crear un chat de IA que responde utilizando tus propios conocimientos.
                            Transforma tu experiencia en una herramienta poderosa para tus usuarios y seguidores,
                            creando un servicio personalizado que aporta valor de manera constante.
                        </p>
                        <a href="https://www.aignis.net" target="_blank" rel="noopener noreferrer"
                            class="group bg-[#4F46E5] text-white px-6 py-3 rounded-lg text-lg font-medium hover:bg-[#4F46E5]/90 transition-colors duration-300 inline-block text-center">
                            Comienza Ahora
                            <ArrowRight
                                class="inline-block ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
                        </a>
                    </div>
                    <div class="flex-1 relative">
                        <div
                            class="absolute inset-0 bg-gradient-to-r from-[#4F46E5] to-[#7C3AED] rounded-full opacity-20 blur-3xl">
                        </div>
                        <Brain class="w-full h-auto text-[#4F46E5]" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Key Features -->
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
            <div v-for="(feature, index) in features" :key="index"
                class="group hover:shadow-lg transition-all duration-300 border border-gray-100 rounded-lg bg-white">
                <div class="p-6">
                    <h3 class="flex items-center text-xl gap-3 font-semibold mb-3">
                        <component :is="feature.icon"
                            class="w-8 h-8 text-[#4F46E5] group-hover:scale-110 transition-transform duration-300" />
                        {{ feature.title }}
                    </h3>
                    <p class="text-gray-600">{{ feature.description }}</p>
                </div>
            </div>
        </div>

        <!-- Benefits -->
        <div class="mb-12 border-0 bg-gradient-to-br from-[#4F46E5]/5 to-transparent rounded-lg shadow-md">
            <div class="p-8">
                <h2 class="text-2xl font-bold flex items-center gap-3 mb-6">
                    <Sparkles class="w-8 h-8 text-[#4F46E5]" />
                    Ventajas de Aignis para tu negocio
                </h2>
                <div class="grid md:grid-cols-2 gap-6">
                    <div v-for="(benefit, index) in benefits" :key="index" class="flex items-start gap-3 group">
                        <div
                            class="mt-1 w-6 h-6 rounded-full bg-[#4F46E5] text-white flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                            ✓
                        </div>
                        <p class="text-gray-700 flex-1">{{ benefit }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Call to Action -->
        <div class="bg-gradient-to-r from-[#4F46E5] to-[#7C3AED] text-white overflow-hidden rounded-lg shadow-lg">
            <div class="flex flex-col items-center text-center p-8 gap-6">
                <Users class="w-16 h-16 mb-4" />
                <h2 class="text-3xl font-bold">Conviértete en un proveedor de conocimiento personalizado</h2>
                <p class="text-xl text-white/90 max-w-2xl">
                    Ya seas una empresa, un creador de contenido o un experto en cualquier campo, Aignis te ofrece la
                    plataforma para expandir tu alcance y dar acceso a una inteligencia artificial alimentada por tu
                    experiencia.
                </p>
                <a href="https://www.aignis.net" target="_blank" rel="noopener noreferrer"
                    class="group bg-white text-[#4F46E5] px-6 py-3 rounded-lg text-lg font-medium hover:bg-white/90 transition-all duration-300 mt-4">
                    Comienza ahora
                    <ArrowRight
                        class="inline-block ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
            </div>
        </div>

        <!-- Footer -->
        <div class="mt-12 text-center text-gray-600">
            <p class="font-medium">Incorpora inteligencia a tu base de conocimiento con Aignis.</p>
            <p class="mt-2">Contacto: www.aignis.net</p>
        </div>

    </div>
</template>
  
  <script setup>
  import { ref } from 'vue'
  import { ArrowRight, Brain, Clock, Coins, Database, Zap, Users, Lock, TrendingUp, Sparkles } from 'lucide-vue-next'
  
  const features = ref([
    { icon: Database, title: "Entrenamiento personalizado", description: "Alimenta la IA con tus documentos y contenido relevante." },
    { icon: Coins, title: "Monetización sencilla", description: "Convierte tus conocimientos en un servicio de suscripción." },
    { icon: Clock, title: "Acceso 24/7", description: "Tus clientes obtienen respuestas precisas en cualquier momento." },
    { icon: TrendingUp, title: "Escalabilidad", description: "Aignis crece con tu negocio, adaptándose a tus necesidades." },
    { icon: Lock, title: "Control total", description: "Decide qué contenido compartes y cómo lo estructuras." },
    { icon: Zap, title: "Fácil implementación", description: "Plataforma amigable y fácil de configurar." },
  ])
  
  const benefits = ref([
    "Valor añadido para tus usuarios",
    "Reputación y fidelización",
    "Optimización del tiempo",
    "Servicio exclusivo 24/7"
  ])
  </script>

<!-- <style>
        html {
            font-size: 280%; /* Valor base: 100% = 16px por defecto */
        }
        body {
            font-family: Arial, sans-serif;
            font-size: 1rem; /* Equivale a 16px */
        }
        h1 {
            font-size: 2rem; /* 2 veces el tamaño de `html`, o sea 32px */
        }
        p {
            font-size: 1rem; /* Equivalente al tamaño base de `html` */
        }

        .larger-fonts {
            font-size: 120%; /* Aumenta la fuente base de todo el documento en un 20% */
        }
    </style> -->